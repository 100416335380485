import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  HiOutlineTrash,
  HiOutlineArchive,
  HiOutlinePencil,
  HiOutlineLockClosed,
  HiInformationCircle,
} from 'react-icons/hi';

import {trackEvent} from '../../utils/tracking';
import {
  Dataset,
  datasetStatusToStage,
  DATASET_STAGE_TYPE,
  downloadDatasetAsCSV,
} from '../../models/dataset';
import {Card} from '../../core/layout/card';
import {Step} from '../../core/components/step';
import {Modal} from '../../core/layout/modal';
import ReactTooltip from 'react-tooltip';
import {DatasetTour} from '../../core/components/tour/datasets-tour';
import {
  DatasetRequestMetadataButton,
  DatasetRequestMetadataConfirmedModal,
} from './dataset-request-metadata';
import {useAuth} from '../../hooks/auth';
import {HiOutlineDocumentArrowDown} from 'react-icons/hi2';
import {isInternal} from '../../models/auth';
import {useAxios} from 'src/utils/http';

export const DatasetCard = ({
  dataset,
  action,
}: {
  dataset: Dataset;
  action?: (actionType: 'edit' | 'delete' | 'archive' | 'request') => void;
}) => {
  const [currentModal, currentModalChange] = useState<string>();
  const [requestConfirmedModalOpen, requestConfirmedModalOpenChange] =
    useState(false);
  const [datasetTourActive, datasetTourActiveChange] = useState(false);
  const {authState} = useAuth();
  const http = useAxios();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataset]);

  const renderMetadataSampleStep = (dataset: Dataset) => {
    let status: 'current' | 'complete' | 'upcoming';
    switch (datasetStatusToStage(dataset.status)) {
      case DATASET_STAGE_TYPE.DatasetCreated:
        status = 'current';
        break;
      case DATASET_STAGE_TYPE.MetadataRequested:
      case DATASET_STAGE_TYPE.MetadataReady:
      case DATASET_STAGE_TYPE.DatasetOrdered:
      case DATASET_STAGE_TYPE.MetadataEmailSent:
      case DATASET_STAGE_TYPE.DatasetDelivered:
        status = 'complete';
    }

    let content: JSX.Element;
    switch (status) {
      case 'current':
        content = (
          <div
            data-tip={
              dataset.studyCount === 0 ? 'Dataset must not be empty' : ''
            }
          >
            <DatasetRequestMetadataButton
              className="btn btn-primary inline btn-xs"
              onClick={() => action && action('request')}
              datasetId={dataset.id}
              numStudies={dataset.studyCount!}
              disabled={dataset.studyCount! === 0}
              onSuccess={() => requestConfirmedModalOpenChange(true)}
            />
          </div>
        );
        break;
      case 'complete':
        content = (
          <div className="inline-flex items-center divide-x space-x-2">
            <div className="inline-block">Metadata &amp; samples requested</div>
            {/*
            <div className="inline-block pl-2">
              <button className="btn btn-link nopadding" title="Confirmation">
                <HiOutlineTicket className="w-5 h-5" />
              </button>
            </div>
            */}
          </div>
        );
        break;
    }

    return (
      <Step
        data-tour={`dataset-card-step-metadata-sample-${dataset.id}`}
        status={status}
      >
        {content}
      </Step>
    );
  };

  const renderMetadataSampleRetrievalStep = (dataset: Dataset) => {
    let stepStatus: 'current' | 'upcoming' | 'complete';
    let status: 'current' | 'upcoming' | 'complete' | 'completeFull';
    switch (datasetStatusToStage(dataset.status)) {
      case DATASET_STAGE_TYPE.MetadataRequested:
      case DATASET_STAGE_TYPE.MetadataReady:
        stepStatus = 'current';
        status = 'current';
        break;
      case DATASET_STAGE_TYPE.DatasetCreated:
        stepStatus = 'upcoming';
        status = 'upcoming';
        break;
      case DATASET_STAGE_TYPE.MetadataEmailSent:
        stepStatus = 'complete';
        status = 'complete';
        break;
      case DATASET_STAGE_TYPE.DatasetOrdered:
      case DATASET_STAGE_TYPE.DatasetDelivered:
        stepStatus = 'complete';
        status = 'completeFull';
    }

    const modalId = 'renderMetadataSampleStepStatus';
    const modalContent = (
      <Modal
        isOpen={currentModal === modalId}
        onRequestClose={() => currentModalChange(undefined)}
        className="w-96"
      >
        <div className="text-lg mb-3">Metadata &amp; Sample Request Status</div>
        <div className="text-sm text-gray-500 mb-3">
          You can expect a CSV of DICOM metadata within a week of your request.
          Remember to add or update your IP address so you can access the DICOM
          samples via data transfer.
          <br />
          <br />
          After your final filtering and approval, we will deliver the full
          DICOMs within 48 hours.
          <br />
          <br />
          If you haven’t heard from us, please contact us at support@segmed.ai.
        </div>
      </Modal>
    );

    let content: JSX.Element;
    switch (status) {
      case 'current':
        content = (
          <button
            className="btn btn-link inline nopadding"
            onClick={() => {
              currentModalChange(modalId);
            }}
          >
            {'Segmed retrieving metadata & samples'}
          </button>
        );
        break;
      case 'complete':
        content = (
          <Link
            className="btn btn-primary inline btn-xs"
            to={`/datasets/${dataset.id}/download`}
          >
            Go to sample downloads
          </Link>
        );
        break;
      case 'completeFull':
        content = (
          <Link
            className="btn btn-link inline nopadding"
            to={`/datasets/${dataset.id}/download`}
          >
            Go to sample downloads
          </Link>
        );
        break;
      case 'upcoming':
        content = <>{'Metadata & sample retrieval'}</>;
        break;
    }

    return (
      <Step
        data-tour={`dataset-card-step-sample-retrieval-${dataset.id}`}
        status={stepStatus}
      >
        {content}
        {modalContent}
      </Step>
    );
  };

  const renderPlaceFinalOrderStep = (dataset: Dataset) => {
    let status: 'current' | 'complete' | 'upcoming';
    let content: JSX.Element;

    switch (datasetStatusToStage(dataset.status)) {
      case DATASET_STAGE_TYPE.MetadataRequested:
      case DATASET_STAGE_TYPE.DatasetCreated:
      case DATASET_STAGE_TYPE.MetadataReady:
        status = 'upcoming';
        content = <div>Place final DICOM order</div>;
        break;
      case DATASET_STAGE_TYPE.MetadataEmailSent:
        status = 'current';
        content = (
          <Link to={`/datasets/${dataset.id}`} className="link">
            Place final DICOM order
          </Link>
        );
        break;
      case DATASET_STAGE_TYPE.DatasetOrdered:
      case DATASET_STAGE_TYPE.DatasetDelivered:
        status = 'complete';
        content = <>DICOM order received</>;
        break;
    }

    return (
      <Step
        data-tour={`dataset-card-step-dicom-order-${dataset.id}`}
        status={status}
      >
        {content}
      </Step>
    );
  };

  const renderRetrievalStep = (dataset: Dataset) => {
    let status: 'current' | 'complete' | 'upcoming';
    let content: JSX.Element;

    switch (datasetStatusToStage(dataset.status)) {
      case DATASET_STAGE_TYPE.MetadataRequested:
      case DATASET_STAGE_TYPE.DatasetCreated:
      case DATASET_STAGE_TYPE.MetadataReady:
      case DATASET_STAGE_TYPE.MetadataEmailSent:
        status = 'upcoming';
        content = <>DICOM retrieval</>;
        break;
      case DATASET_STAGE_TYPE.DatasetOrdered:
        status = 'current';
        content = <>DICOM retrieval</>;
        break;
      case DATASET_STAGE_TYPE.DatasetDelivered:
        status = 'complete';
        content = (
          <Link
            className="btn btn-primary inline btn-xs"
            to={`/datasets/${dataset.id}/download`}
          >
            DICOM retrieval
          </Link>
        );
        break;
    }

    return (
      <Step
        data-tour={`dataset-card-step-dicom-retrieval-${dataset.id}`}
        status={status}
      >
        {content}
      </Step>
    );
  };

  return (
    <>
      <Card data-cy="datasetCard">
        <div className="text-gray-400 flex justify-between items-center mb-6 pb-6 border-b">
          <div>
            <div className="flex text-gray-900 items-center">
              <Link
                className="btn btn-lg btn-link inline px-0"
                data-tour={`dataset-card-step-name-${dataset.id}`}
                to={`/datasets/${dataset.id}`}
                onClick={() => {
                  trackEvent('CLICK_VIEW_DATASET_DETAILS_BTN', {
                    datasetId: dataset.id,
                  });
                }}
              >
                {dataset.name}
              </Link>
              <HiInformationCircle
                className="mx-1 text-xl text-primary hover:text-primary-active hover:cursor-pointer"
                data-cy="datasetInfoButton"
                onClick={() => {
                  trackEvent('CLICK_DATASET_TOUR_BTN');
                  datasetTourActiveChange(true);
                }}
              />
            </div>
          </div>
          <div>
            {isInternal(authState.profile ? authState.profile.email : '') && (
              <>
                <div className="inline ml-4">
                  <button
                    className="btn btn-link inline-block px-0 py-0"
                    data-tip="Download dataset as .CSV file"
                    data-event="mouseenter"
                    data-event-off="mouseleave click"
                    onClick={() => {
                      trackEvent('CLICK_DOWNLOAD_DATASET_AS_CSV_BTN', {
                        datasetId: dataset.id,
                      });
                      downloadDatasetAsCSV(http, dataset.id);
                    }}
                  >
                    <HiOutlineDocumentArrowDown className="w-5 h-5 inline" />
                  </button>
                </div>
              </>
            )}
            {datasetStatusToStage(dataset.status) ===
              DATASET_STAGE_TYPE.DatasetCreated && (
              <>
                <div className="inline ml-4">
                  <button
                    className="btn btn-link inline-block px-0 py-0"
                    data-tip="Rename Dataset"
                    data-event="mouseenter"
                    data-event-off="mouseleave click"
                    onClick={() => {
                      trackEvent('CLICK_EDIT_DATASET_BTN', {
                        datasetId: dataset.id,
                      });
                      action && action('edit');
                    }}
                  >
                    <HiOutlinePencil className="w-5 h-5 inline" />
                  </button>
                </div>
                <div className="inline ml-4">
                  <button
                    className="btn btn-link inline-block px-0 py-0"
                    data-tip="Delete Dataset"
                    data-event="mouseenter"
                    data-event-off="mouseleave click"
                    onClick={() => {
                      trackEvent('CLICK_DELETE_DATASET_BTN', {
                        datasetId: dataset.id,
                      });
                      action && action('delete');
                    }}
                  >
                    <HiOutlineTrash className="w-5 h-5 inline" />
                  </button>
                </div>
              </>
            )}
            {datasetStatusToStage(dataset.status) !==
              DATASET_STAGE_TYPE.DatasetCreated && (
              <>
                <div className="inline ml-4">
                  <HiOutlineLockClosed className="w-5 h-5 inline" />
                </div>
                <div className="inline ml-4">
                  <button
                    className="btn btn-link inline-block px-0 py-0"
                    data-tip="Archive Dataset"
                    data-event="mouseenter"
                    data-event-off="mouseleave click"
                    onClick={() => {
                      trackEvent('CLICK_ARCHIVE_DATASET_BTN', {
                        datasetId: dataset.id,
                      });
                      action && action('archive');
                    }}
                  >
                    <HiOutlineArchive className="w-5 h-5 inline" />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div>
              <div
                className="text-2xl font-extrabold"
                data-cy="datasetStudyCount"
              >
                {dataset.studyCount?.toLocaleString('en-US')}
              </div>
              <div className="text-gray-500">Total Studies</div>
            </div>
          </div>
          <div className="space-y-4">
            {renderMetadataSampleStep(dataset)}
            {renderMetadataSampleRetrievalStep(dataset)}
            {renderPlaceFinalOrderStep(dataset)}
            {renderRetrievalStep(dataset)}
          </div>
        </div>
        <DatasetTour
          datasetId={dataset.id}
          tourActive={datasetTourActive}
          tourActiveChange={datasetTourActiveChange}
        />
      </Card>

      {/* @todo: Use a global component for confirm windows */}
      <DatasetRequestMetadataConfirmedModal
        isOpen={requestConfirmedModalOpen}
        onRequestClose={() => requestConfirmedModalOpenChange(false)}
      />
    </>
  );
};
