import {DateTime} from 'luxon';
import queryString from 'query-string';
import {AxiosInstance, AxiosResponse} from 'axios';
import {map} from 'lodash';

import {
  responseToPresignedDatasetLocation,
  PresignedDatasetLocation,
  fetchFileBlobLocation,
} from './dataset';

export interface UserFileInfo {
  locationID: number;
  userID: number;
  firstName: string;
  lastName: string;
  orgName: string;
  location: string;
  filename: string;
  size: number;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface UserFileInfoResp {
  LocationID: number;
  UserID: number;
  FirstName: string;
  LastName: string;
  OrgName: string;
  Location: string;
  Filename: string;
  Size: number;
  CreatedAt: string;
  UpdatedAt: string;
}

export const responseToUserFileInfo = (
  userFileInfoResp: UserFileInfoResp
): UserFileInfo => {
  return {
    userID: userFileInfoResp.UserID,
    firstName: userFileInfoResp.FirstName,
    lastName: userFileInfoResp.LastName,
    orgName: userFileInfoResp.OrgName,
    location: userFileInfoResp.Location,
    locationID: userFileInfoResp.LocationID,
    filename: userFileInfoResp.Filename,
    size: userFileInfoResp.Size,
    createdAt: DateTime.fromISO(userFileInfoResp.CreatedAt),
    updatedAt: DateTime.fromISO(userFileInfoResp.UpdatedAt),
  };
};

export const getAllUploadedFiles = (
  http: AxiosInstance,
  page: number,
  resultsPerPage: number
) => {
  return http
    .get(
      `v1/data_partner/all_files?page[]=${page}&resultsPerPage[]=${resultsPerPage}`
    )
    .then((response: AxiosResponse<UserFileInfoResp[]>) => {
      const {data} = response;
      return data.map((userFileInfo: UserFileInfoResp) => {
        return responseToUserFileInfo(userFileInfo);
      });
    });
};

export const getCountAllUploadedFiles = (http: AxiosInstance) => {
  return http
    .get('v1/data_partner/all_files_count')
    .then((response: AxiosResponse<number>) => {
      const {data} = response;
      return data;
    });
};

export const getPresignedDownloadUrls = (
  http: AxiosInstance,
  locationIDs: number[]
) => {
  return http
    .post(
      'v1/data_partner/download',
      queryString.stringify(
        {
          locationIDs: locationIDs,
        },
        {arrayFormat: 'bracket'}
      )
    )
    .then(response => {
      const {data} = response;
      const presignedDatasetLocations = map(
        data,
        presignedDatasetLocationResponse =>
          responseToPresignedDatasetLocation(presignedDatasetLocationResponse)
      );
      return presignedDatasetLocations;
    });
};

export const downloadFilesWithPresignedLocations = (
  presignedLocations: PresignedDatasetLocation[]
) => {
  presignedLocations.forEach((loc, i) =>
    setTimeout(() => downloadFileFromURL(loc.presignedURL), 500 * i)
  );
};

export const downloadFileFromURL = (URL: string) => {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(URL, '_blank');
};

export const downloadFileLocally = async (
  http: AxiosInstance,
  fileURL: string,
  datasetId: number
) => {
  try {
    const response = await fetchFileBlobLocation(http, fileURL);
    const urlObj = new URL(fileURL);
    const pathname = urlObj.pathname;
    let fileName: string;
    if (fileURL.includes('export/csv') && datasetId !== 0) {
      const date = new Date();
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}${date.getFullYear()}`;
      fileName = `${formattedDate}_dataset_${datasetId}.csv`;
    } else {
      fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    }
    const defaultFileExtension = fileName.split('.').pop();
    let mimeType;
    switch (defaultFileExtension) {
      case 'zip':
        mimeType = 'application/zip';
        break;
      case 'csv':
        mimeType = 'text/csv';
        break;
      default:
        mimeType = 'application/octet-stream'; // generic binary data MIME type
        break;
    }
    const blob = new Blob([response.data], {type: mimeType});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error fetching and downloading file:', error);
  }
};
