import {AxiosInstance} from 'axios';
import {Duration} from 'luxon';

export interface PresignedThumbnailLocation {
  studyID: string;
  presignedURL: string;
  accessDuration: Duration;
}

export const responseToPresignedThumbnailLocation =
  (presignedThumbnailLocationResp: {
    study_id: string;
    presigned_url: string;
    access_duration: number;
  }): PresignedThumbnailLocation => {
    return {
      studyID: presignedThumbnailLocationResp.study_id,
      presignedURL: presignedThumbnailLocationResp.presigned_url,
      accessDuration: Duration.fromMillis(
        presignedThumbnailLocationResp.access_duration
      ),
    };
  };

export const getPresignedStudyThumbnailUrl = (
  http: AxiosInstance,
  studyID: string
) => {
  return http.post(`/v1/study_thumbnail/${studyID}`).then(response => {
    const {data} = response;
    const presignedThumbnailsLocation =
      responseToPresignedThumbnailLocation(data);
    return presignedThumbnailsLocation;
  });
};
