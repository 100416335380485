export const Auth0Constants = {
  development: {
    domain: 'dev-7rlu5yoqkr7d4h5p.us.auth0.com',
    clientId: 'iQu35lh50iqqzzWntiBS3VCUg0OljwT5',
    audience: 'https://openda.segmed.ai',
  },
  staging: {
    domain: 'auth.d.segmed.ai',
    clientId: 'Q2hAbBDcC4QZI2H5HKkWHsXKxyI3SjEp',
    audience: 'https://openda.segmed.ai',
  },
  production: {
    domain: 'auth.segmed.ai',
    clientId: 'nytMMbukWx7vUWawXSgu5icAUk4v8m6Z',
    audience: 'https://openda.segmed.ai',
  },
};
