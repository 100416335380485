import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {MinervaSearch} from './minerva-search';
import {HiOutlineArrowRight} from 'react-icons/hi';
import {
  buildDisjunctiveFacetsConfigFromConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from '../../../config/config-helper';

import ElasticsearchAPIConnector, {
  ElasticsearchTransporter,
  SearchRequest,
} from '@segmed/search-ui-elasticsearch-connector';
import {ExtendedSearchDriverOptions} from '../../../models/minerva';
import _ from 'lodash';
import {
  extractSearchTerms,
  extractUniquePatientCount,
  trackSearchEvent,
} from 'src/models/search';
import {PatientSortOption, SortOption} from '../../../models/minerva';
import {useAxios} from 'src/utils/http';

const {
  endpointBaseInternal,
  engineNameInternal,
  endpointBaseAppSearchInternal,
} = getConfig();

export const MinervaSearchInternalPage = () => {
  const [patientCount, patientCountChange] = useState<number | null>(null);
  const [lastRequestBody, lastRequestBodyChange] = useState<SearchRequest>();

  const http = useAxios();
  const connector = new ElasticsearchAPIConnector(
    new ElasticsearchTransporter(
      endpointBaseInternal,
      engineNameInternal,
      async (host, _engineName, requestBody) => {
        const startTime = performance.now(); // Start time
        const response = await http.post(`${host}`, requestBody);
        const duration = performance.now() - startTime; // Calculate duration
        patientCountChange(extractUniquePatientCount(response.data));
        trackSearchEvent(
          requestBody,
          (requestBody as any)['groupPatientIDs'],
          (requestBody as any)['sortPatientIDsBy'],
          response.data?.aggregations?.facet_bucket_all?.doc_count,
          duration // Pass duration
        );
        lastRequestBodyChange(requestBody);
        return response.data;
      }
    ),
    (requestBody, _requestState, queryConfig) => {
      const queryBuilderQuery = (queryConfig as {queryBuilderQuery?: Object})
        .queryBuilderQuery;
      if (!_.isEmpty(queryBuilderQuery)) {
        requestBody.query = queryBuilderQuery;
      }

      const groupPatientIDs = (queryConfig as {groupPatientIDs?: boolean})
        .groupPatientIDs;
      const sortPatientIDsBy = (queryConfig as {sortPatientIDsBy?: boolean})
        .sortPatientIDsBy;
      if (groupPatientIDs) {
        (requestBody as any)['groupPatientIDs'] = true;
        (requestBody as any)['sortPatientIDsBy'] = sortPatientIDsBy;
      }
      const searchTerms = extractSearchTerms(requestBody);
      if (searchTerms && searchTerms.size > 0) {
        requestBody.highlight!.highlight_query = {
          bool: {
            must: {
              bool: {
                should: Array.from(searchTerms).map(term => ({
                  multi_match: {
                    query: term,
                  },
                })),
              },
            },
          },
        };
      }

      return requestBody;
    }
  );
  const [config, setConfig] = useState<ExtendedSearchDriverOptions>({
    searchQuery: {
      facets: buildFacetConfigFromConfig(),
      disjunctiveFacets: buildDisjunctiveFacetsConfigFromConfig(),
      ...buildSearchOptionsFromConfig(),
      groupPatientIDs: false,
      sortPatientIDsBy: 'Exam date DESC',
    },
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true,
    initialState: {
      resultsPerPage: 50,
      sortList: [{field: 'study_id.keyword', direction: 'asc'}] as SortOption[],
    },
    appSearchEndpoint: endpointBaseAppSearchInternal,
  });

  // Function to change groupPatientIDs
  const toggleGroupPatientIDs = () => {
    setConfig(prevConfig => ({
      ...prevConfig,
      searchQuery: {
        ...prevConfig.searchQuery,
        groupPatientIDs: !prevConfig.searchQuery.groupPatientIDs,
      },
    }));
  };

  const setSortPatientIDsBy = (PatientSortOption: PatientSortOption) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      searchQuery: {
        ...prevConfig.searchQuery,
        sortPatientIDsBy: PatientSortOption,
      },
    }));
  };

  const onSubmit = (query?: Object) => {
    if (!_.isEqual(query, config.searchQuery.queryBuilderQuery)) {
      setConfig(prevConfig => ({
        ...prevConfig,
        searchQuery: {
          ...prevConfig.searchQuery,
          queryBuilderQuery: query,
        },
      }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Search (Internal)</title>
      </Helmet>

      <div className="w-full w-max-full">
        <ApplicationShell
          bgcolor="bgcolor"
          contained={false}
          navbar={
            <div className="navbar">
              <div className="ml-auto text-sm text-gray-700">
                Having trouble finding data?
                <a
                  className="link ml-1"
                  href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a help session
                  <HiOutlineArrowRight className="inline-block ml-1" />
                </a>
              </div>
            </div>
          }
          noPadding
        >
          <MinervaSearch
            config={config}
            lastRequestBody={lastRequestBody}
            toggleGroupPatientIDs={toggleGroupPatientIDs}
            onSubmit={onSubmit}
            patientCount={patientCount}
            splitView={true}
            setSortPatientIDsBy={setSortPatientIDsBy}
            isInternalPage={true}
          />
        </ApplicationShell>
      </div>
    </>
  );
};
