import {useQuery} from 'react-query';
import {getPresignedStudyThumbnailUrl} from '../../../models/study-thumbnail';
import {useAxios} from 'src/utils/http';

export const DicomThumbnail = ({studyID}: {studyID: string}) => {
  const api = useAxios();

  const {data: dicomThumbnail} = useQuery(
    ['dicomThumbnail', studyID],
    () => getPresignedStudyThumbnailUrl(api, studyID),
    {
      staleTime: Infinity,
    }
  );

  return (
    <>
      {dicomThumbnail && (
        <img
          className="w-full aspect-h-1 my-2"
          src={dicomThumbnail.presignedURL}
          alt={`thumbnail for study ID: ${dicomThumbnail.studyID}`}
        />
      )}
    </>
  );
};
