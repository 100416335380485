import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Auth0Provider} from '@auth0/auth0-react';
import * as cornerstone from '@cornerstonejs/core';
import {QueryClient, QueryClientProvider} from 'react-query';
import _ from 'lodash';

import './styles/index.css';
import App from './app';
import {Auth0Constants} from './models/auth0';
import {quickToast} from './core/components/toast';

Sentry.init({
  dsn: 'https://c7291d6827db40bda64151ed5320f9c8@o489856.ingest.sentry.io/4503903742197760',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
});

cornerstone.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: err => {
        const message = (err as any)?.response?.data?.message;
        if (message !== undefined && _.isString(message)) {
          quickToast({
            title:
              (err as any)?.response?.data?.message ?? 'Error fetching data',
            icon: 'error',
          });
        }
      },
    },
    mutations: {
      onError: err => {
        const message = (err as any)?.response?.data?.message;
        if (message !== undefined && _.isString(message)) {
          quickToast({
            title: (err as any)?.response?.data?.message ?? 'Request error',
            icon: 'error',
          });
        }
      },
    },
  },
});

export const currentAuth0Constants =
  window.location.host === 'openda.segmed.ai'
    ? Auth0Constants.production
    : window.location.host === 'insight.d.segmed.ai'
    ? Auth0Constants.staging
    : process.env.REACT_APP_AUTH_ENV === 'development'
    ? Auth0Constants.development
    : Auth0Constants.production;

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider
      domain={currentAuth0Constants.domain}
      clientId={currentAuth0Constants.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: currentAuth0Constants.audience,
      }}
      cacheLocation="localstorage"
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
