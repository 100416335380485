import {Helmet} from 'react-helmet';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {MinervaImageSearch} from './minerva-image-search';
import {HiOutlineArrowRight} from 'react-icons/hi';
import {
  buildDisjunctiveFacetsConfigFromConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from '../../../config/config-helper';

import ElasticsearchAPIConnector, {
  ElasticsearchTransporter,
} from '@segmed/search-ui-elasticsearch-connector';
import {useAxios} from 'src/utils/http';

const {endpointBaseViewable, engineNameViewable} = getConfig();

export const MinervaImageSearchPage = () => {
  const http = useAxios();
  const connector = new ElasticsearchAPIConnector(
    new ElasticsearchTransporter(
      endpointBaseViewable,
      engineNameViewable,
      (host, _engineName, requestBody) => {
        return http.post(`${host}`, requestBody).then(response => {
          return response.data;
        });
      }
    )
  );

  const config = {
    searchQuery: {
      facets: buildFacetConfigFromConfig(),
      disjunctiveFacets: buildDisjunctiveFacetsConfigFromConfig(),
      ...buildSearchOptionsFromConfig(),
    },
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true,
    initialState: {
      resultsPerPage: 50,
    },
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Search</title>
      </Helmet>

      <div className="w-full w-max-full">
        <ApplicationShell
          bgcolor="bgcolor"
          contained={false}
          navbar={
            <div className="navbar">
              <div className="ml-auto text-sm text-gray-700">
                Having trouble finding data?
                <a
                  className="link ml-1"
                  href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a help session
                  <HiOutlineArrowRight className="inline-block ml-1" />
                </a>
              </div>
            </div>
          }
          noPadding
        >
          <MinervaImageSearch config={config} />
        </ApplicationShell>
      </div>
    </>
  );
};
