import {Fragment, ReactNode, useMemo, useEffect} from 'react';
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiSortAscending,
  HiSortDescending,
} from 'react-icons/hi';
import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  SortingRule,
  HeaderGroup,
  Row,
} from 'react-table';
import cx from 'classnames';

import {ColumnDropdown} from '../../../core/components/dropdown';
import {Pagination} from '../../../core/components/pagination';

type SortType = 'dropdown' | 'toggle';
type DicomRequestStatus = {
  dicomRequestStatus: string;
};

export const ReactTable = ({
  tableColumns,
  tableData,
  paginationDisabled = false,
  resultsPerPage = 100,
  showCountDesc,
  renderCustomShownCount,
  defaultSortBy,
  sortDisabled = false,
  sortType = 'dropdown',
  restrictHeight = true,
  onPageChange,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableColumns: Column<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: any[];
  paginationDisabled?: boolean;
  resultsPerPage?: number;
  showCountDesc?: string;
  renderCustomShownCount?: (
    pageSize: number,
    pageIndex: number,
    rowsLength: number
  ) => ReactNode;
  defaultSortBy?: SortingRule<{id: string; desc: true}>[];
  sortDisabled?: boolean;
  sortType?: SortType;
  restrictHeight?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageChange?: (page: Row<any>[]) => void;
}) => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);

  const renderDefaultShownCount = (
    desc: string,
    pageSize: number,
    pageIndex: number,
    rowsLength: number
  ) => {
    return (
      <>
        Showing {(pageSize * pageIndex + 1).toLocaleString('en-US')}-
        {Math.min(pageSize * (pageIndex + 1), rowsLength).toLocaleString(
          'en-US'
        )}{' '}
        of {rowsLength.toLocaleString('en-US')} {desc}
      </>
    );
  };

  const renderSort = (column: HeaderGroup<object>, sortType: SortType) => {
    if (sortDisabled) {
      return (
        <span {...column.getSortByToggleProps()}>
          {column.render('Header')}
        </span>
      );
    }
    switch (sortType) {
      case 'toggle':
        return (
          <span {...column.getSortByToggleProps()}>
            {column.render('Header')}
            {column.isSorted && (
              <span className="inline-block">
                {column.isSortedDesc ? (
                  <>
                    {' '}
                    <HiOutlineChevronDown />
                  </>
                ) : (
                  <>
                    {' '}
                    <HiOutlineChevronUp />
                  </>
                )}
              </span>
            )}
          </span>
        );
      case 'dropdown':
      default:
        return (
          <div className="inline-block relative">
            <ColumnDropdown
              label={
                <>
                  {column.render('Header')}
                  {column.canSort && (
                    <span className="inline-block">
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <>
                            {' '}
                            <HiOutlineChevronDown />
                          </>
                        ) : (
                          <>
                            {' '}
                            <HiOutlineChevronUp />
                          </>
                        ))}
                    </span>
                  )}
                </>
              }
              disabled={
                (!column.canFilter && !column.canSort) ||
                (column.columns?.length ?? 0) > 0
              }
            >
              <div className="text-sm font-normal space-y-2">
                {column.canSort && (
                  <div className="space-y-2">
                    <button
                      className={cx('text-gray-700 hover:text-gray-800 block', {
                        'font-medium text-gray-800':
                          column.isSorted && !column.isSortedDesc,
                      })}
                      disabled={column.isSorted && !column.isSortedDesc}
                      onClick={() => column.toggleSortBy(false)}
                    >
                      <HiSortAscending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                      Sort Ascending (A-Z)
                    </button>
                    <button
                      className={cx('text-gray-700 hover:text-gray-800 block', {
                        'font-medium text-gray-800':
                          column.isSorted && column.isSortedDesc,
                      })}
                      disabled={column.isSorted && column.isSortedDesc}
                      onClick={() => column.toggleSortBy(true)}
                    >
                      <HiSortDescending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                      Sort Descending (Z-A)
                    </button>
                  </div>
                )}

                {column.filter && column.render('Filter')}
              </div>
            </ColumnDropdown>
          </div>
        );
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    state: {pageIndex, pageSize},
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      disableSortRemove: true,
      disableSortBy: sortDisabled,
      initialState: {
        sortBy: defaultSortBy ?? [],
        pageSize: !paginationDisabled
          ? resultsPerPage
          : Number.MAX_SAFE_INTEGER,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onPageChange && onPageChange(page);
  }, [page, onPageChange]);

  const baseClass = 'px-6 py-4 whitespace-nowrap text-sm';
  const okRowStyle = `${baseClass} bg-green-100`;
  const errorRowStyle = `${baseClass} bg-red-100`;
  const defaultRowStyle = `${baseClass}`;

  return (
    <>
      {renderCustomShownCount
        ? renderCustomShownCount(pageSize, pageIndex, rows.length)
        : showCountDesc &&
          renderDefaultShownCount(
            showCountDesc,
            pageSize,
            pageIndex,
            rows.length
          )}
      <div
        className={cx(
          'shadow overflow-x-scroll border-b border-gray-200 sm:rounded-lg',
          {
            'max-h-screen': restrictHeight,
          }
        )}
      >
        <table
          {...getTableProps({
            className: 'min-w-full divide-y divide-gray-200',
          })}
        >
          <thead className="bg-gray-50 divide-y divide-gray-200">
            {headerGroups.map(headerGroup => {
              const {key, ...headerGroupProps} =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...headerGroupProps}>
                  {headerGroup.headers.map(column => {
                    const {key, ...headerProps} = column.getHeaderProps({
                      className:
                        'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap',
                    });
                    return (
                      <th key={key} {...headerProps}>
                        {renderSort(column, sortType)}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody
            {...getTableBodyProps({
              className: 'bg-white divide-y divide-gray-200',
            })}
          >
            {page.map((row, rowIndex) => {
              prepareRow(row);

              const rowStatus = (row.original as DicomRequestStatus)
                .dicomRequestStatus;
              let currentRowStyle = defaultRowStyle;
              if (rowStatus !== undefined) {
                currentRowStyle =
                  rowStatus === 'OK' ? okRowStyle : errorRowStyle; // Green for 'OK', red otherwise
              }
              return (
                <Fragment key={rowIndex}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      const {key, ...cellProps} = cell.getCellProps({
                        className: currentRowStyle,
                      });
                      return (
                        <td key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {!paginationDisabled && (
        <Pagination
          max={pageCount}
          current={pageIndex + 1}
          linkFunc={num => {
            gotoPage(num - 1);
          }}
        />
      )}
    </>
  );
};
