/* eslint-disable security/detect-object-injection */
import {useState} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Helmet} from 'react-helmet';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {HiOutlinePlus} from 'react-icons/hi';
import {AxiosError} from 'axios';

import {trackEvent} from '../../../utils/tracking';
import {
  archiveDataset,
  Dataset,
  fetchAllDatasets,
  responseToDataset,
  editDataset,
  deleteDataset,
} from '../../../models/dataset';
import {ToastMessage} from '../../../core/components/toast';
import {Modal} from '../../../core/layout/modal';
import {Loading} from '../../../core/components/loading';
import {CreateDatasetForm} from '../../../components/create-dataset-form';
import {DatasetCard} from '../../../components/dataset';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {EmbedVideo} from '../../../core/components/embed-video';
import {DatasetStatusEnum} from 'src/enums/datasetstatusenum';
import {useAxios} from 'src/utils/http';

export const DatasetList = () => {
  const queryClient = useQueryClient();
  const api = useAxios();

  const [modalType, modalTypeChange] = useState<
    'create' | 'edit' | 'request' | 'requestConfirmed' | 'delete' | 'archive'
  >();
  const [modalOpen, modalOpenChange] = useState(false);
  const [modalDataset, modalDatasetChange] = useState<Dataset>();

  const {data: datasets, isLoading: datasetsLoading} = useQuery(
    ['dataset'],
    () => fetchAllDatasets(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const editDatasetMut = useMutation(
    ({datasetId, name}: {datasetId: number; name: string}) =>
      editDataset(api, datasetId, name),
    {
      onSuccess: response => {
        // Success
        const {data} = response;

        const dataset = responseToDataset(data);

        // Update currently loaded dataset
        const index = _.findIndex(datasets, {id: dataset.id});
        const newDatasets = _.cloneDeep(datasets);
        newDatasets![index].name = data['name'];
        queryClient.setQueryData(['dataset'], newDatasets);

        toast(<ToastMessage title="Dataset updated" icon="success" />);

        closeModal();
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error updating dataset';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const deleteDatasetMut = useMutation(
    ({datasetId}: {datasetId: number}) => deleteDataset(api, datasetId),
    {
      onSuccess: (response, {datasetId}) => {
        // Success
        toast(<ToastMessage title="Dataset deleted" icon="success" />);

        // Update currently loaded dataset
        const newDatasets = _.filter(
          datasets,
          dataset => dataset.id !== datasetId
        );
        queryClient.setQueryData(['dataset'], newDatasets);

        closeModal();
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error deleting dataset';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const archiveDatasetMut = useMutation(
    ({datasetId}: {datasetId: number}) => archiveDataset(api, datasetId),
    {
      onSuccess: datasetId => {
        // Update currently loaded dataset
        const newDatasets = _.filter(
          datasets,
          dataset => dataset.id !== datasetId
        );
        queryClient.setQueryData(['dataset'], newDatasets);
        closeModal();
      },
    }
  );

  // @todo: Create component for each modal
  const openModal = (
    type:
      | 'create'
      | 'edit'
      | 'request'
      | 'requestConfirmed'
      | 'delete'
      | 'archive',
    dataset?: Dataset
  ) => {
    // @todo: Don't use empty dataset for create modal
    const emptyDataset: Dataset = {
      id: 0,
      userId: 0,
      name: '',
      status: DatasetStatusEnum.DatasetStatusDatasetCreated,
      includeUndetermined: true,
      favorite: true,
      dicomViewingProcessing: false,
    };

    modalTypeChange(type);
    modalDatasetChange(_.cloneDeep(dataset || emptyDataset));
    modalOpenChange(true);
  };

  const closeModal = () => {
    modalOpenChange(false);
    modalTypeChange(undefined);
    modalDatasetChange(undefined);
  };

  const renderDatasets = () => {
    return (
      <div className="min-w-full space-y-4">
        {_.map(datasets, (dataset, i) => {
          return (
            <DatasetCard
              dataset={dataset}
              key={i}
              action={actionType => {
                switch (actionType) {
                  case 'edit':
                  case 'archive':
                  case 'delete':
                    openModal(actionType, dataset);
                    break;
                  case 'request':
                    // do nothing
                    break;
                }
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderEditModal = (dataset: Dataset) => {
    return (
      <div>
        <div className="text-lg mb-3">Rename Dataset</div>
        <div className="mb-3">
          <label htmlFor="name" className="input-label">
            New name
          </label>
          <input
            id="name"
            type="text"
            className="text-input w-full mt-2"
            value={dataset.name}
            autoComplete="off"
            onChange={e => {
              dataset.name = e.target.value;
              modalDatasetChange(_.cloneDeep(dataset));
            }}
          />
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-primary"
            onClick={() => {
              editDatasetMut.mutate({
                datasetId: dataset!.id,
                name: dataset!.name,
              });
            }}
            disabled={editDatasetMut.isLoading}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  const renderDeleteModal = (dataset: Dataset) => {
    return (
      <div>
        <div className="text-lg mb-3">Delete Dataset</div>
        <div className="text-sm text-gray-500 mb-3">
          All reports and tags saved to this dataset will be lost.
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-danger"
            data-cy="confirmDeleteDataset"
            onClick={() => {
              deleteDatasetMut.mutate({datasetId: dataset!.id});
            }}
            disabled={deleteDatasetMut.isLoading}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const renderArchiveModal = (dataset: Dataset) => {
    return (
      <div>
        <div className="text-lg mb-3">Archive Dataset</div>
        <div className="text-sm text-gray-500 mb-3">
          This dataset will be archived and will be no longer accessible thru
          this page, do you want to continue?
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-danger"
            onClick={() => {
              archiveDatasetMut.mutate({datasetId: dataset!.id});
            }}
            disabled={archiveDatasetMut.isLoading}
          >
            Archive
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Datasets</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        <div className="mb-5 flex justify-between">
          <h1 className="text-3xl">Your Datasets</h1>
          <button
            className="btn btn-white"
            onClick={() => {
              trackEvent('CLICK_CREATE_DATASET_BTN');
              openModal('create');
            }}
          >
            <span className="text-gray-300 pr-2">
              <HiOutlinePlus className="w-6 h-6" />
            </span>
            New Dataset
          </button>
        </div>

        <div>
          {datasetsLoading ? (
            <Loading text="Loading datasets..." />
          ) : datasets?.length ? (
            renderDatasets()
          ) : (
            <div className="flex flex-col">
              <div className="justify-self-center text-base leading-7 font-normal text-gray-500 pb-4">
                You don&apos;t have any datasets yet. Start a{' '}
                <Link className="link" to="/">
                  search
                </Link>{' '}
                to build one.
              </div>
              <div className="grid grid-rows-1 grid-cols-2 gap-x-4">
                <div className="border border-transparent rounded-md shadow-sm border-gray-300 z-10 overflow-hidden">
                  <EmbedVideo src="https://www.loom.com/embed/6076d589bf4949369bbd9f6246137830?hide_share=true&hide_owner=true&hideEmbedTopBar=true" />
                  <div className="ml-2 py-2">
                    <a
                      className="link"
                      href="https://www.loom.com/share/6076d589bf4949369bbd9f6246137830"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => trackEvent('CLICK_DEMO_HOW_TO_SEARCH_BTN')}
                    >
                      How to search &rarr;
                    </a>
                  </div>
                </div>
                <div className="border border-transparent rounded-md shadow-sm border-gray-300 z-10 overflow-hidden">
                  <EmbedVideo src="https://www.loom.com/embed/1a183ebc11c247eb88d78036211ad9d1?hide_share=true&hide_owner=true&hideEmbedTopBar=true" />
                  <div className="ml-2 py-2">
                    <a
                      className="link"
                      href="https://www.loom.com/share/1a183ebc11c247eb88d78036211ad9d1"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => trackEvent('CLICK_DEMO_HOW_TO_ORDER_BTN')}
                    >
                      How to order data &rarr;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ApplicationShell>

      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        className="w-96 max-w-lg"
        hideExitButton={_.includes(['requestConfirmed'], modalType)}
      >
        {modalType === 'edit' && renderEditModal(modalDataset!)}
        {modalType === 'delete' && renderDeleteModal(modalDataset!)}
        {modalType === 'archive' && renderArchiveModal(modalDataset!)}
        {modalType === 'create' && (
          <CreateDatasetForm allowCSVUpload closeModal={closeModal} />
        )}
      </Modal>
    </>
  );
};
