import React, {useCallback, useEffect, useState} from 'react';
import {trackEvent} from '../../utils/tracking';
import {HiChevronDown, HiChevronRight, HiOutlineXCircle} from 'react-icons/hi';
import {Select, SelectOption} from '../../core/components/select';
import _ from 'lodash';
import cx from 'classnames';
import {
  BoolQuery,
  MultiMatchQuery,
  spanNearQuery,
  MatchPhraseQuery,
  spanTermQuery,
  WildcardQuery,
} from 'elastic-builder';
import OutsideClickHandler from 'react-outside-click-handler';
import {useQuery} from 'react-query';

import {fetchSnomedConceptSuggestions} from 'src/models/snomed-concept';
import {useAxios} from 'src/utils/http';

export interface TextField {
  id: string;
  value: string;
  operation: string;
  reportType: 'Report' | 'Impression';
  snomedTerms?: string[];
}

export const newTextField = (value?: string): TextField => {
  return {
    id: _.uniqueId(),
    value: value ?? '',
    operation: 'contains substring',
    reportType: 'Report',
  };
};

const maxFields = 10;

export const textFieldReportOptions: SelectOption<'Report' | 'Impression'>[] = [
  {value: 'Report', label: 'Report'},
  {value: 'Impression', label: 'Impression'},
];

export const textFieldOperationOptions: SelectOption<string>[] = [
  {value: 'contains substring', label: 'Contains'},
  {value: 'excludes substring', label: 'Excludes'},
  {
    value: 'contains substring (no negations)',
    label: 'Contains (No Negations)',
  },
];

function buildBaseQuery(fields: string[], value: string) {
  return new BoolQuery().should([
    new MultiMatchQuery(fields, value).type('best_fields').operator('and'),
    new MultiMatchQuery(fields, value).type('cross_fields'),
    new MultiMatchQuery(fields, value).type('phrase'),
    new MultiMatchQuery(fields, value).type('phrase_prefix'),
  ]);
}

function handleExactMatch(term: string, field: TextField) {
  const phrase = term.slice(1, -1); // Remove the quotes

  switch (field.reportType) {
    case 'Report':
      switch (field.operation) {
        case 'contains substring':
          return new MatchPhraseQuery('report', phrase).slop(0);
        case 'excludes substring':
          return new BoolQuery().mustNot(
            new MatchPhraseQuery('report', phrase).slop(0)
          );
        case 'contains substring (no negations)':
          return new MatchPhraseQuery('report_no_negations', phrase).slop(0);
        default:
          throw new Error(`Unsupported operation: ${field.operation}`);
      }
    case 'Impression':
      // This is not an exact match, but the same logic for impressions. Unfortunately, the query builder doesn't support exact matches for span queries.
      return spanNearQuery()
        .clauses([
          spanTermQuery('report', 'impression'),
          spanTermQuery('report', term),
        ])
        .slop(50) // Allow up to 50 words between the two terms
        .inOrder(true);
    default:
      throw new Error(`Unsupported report type: ${field.reportType}`);
  }
}

function removeTextInParentheses(input: string): string {
  return input.replace(/\s*\([^)]*\)/g, '').trim();
}

function couldBeAnID(term: string): boolean {
  return term.length >= 7 && /^[0-9]+$/.test(term);
}

function handleIDs(term: string) {
  const patientIdQuery = new WildcardQuery('patient_id', `*${term}`);
  const studyIdQuery = new WildcardQuery('study_id', `*${term}`);
  return {patientIdQuery: patientIdQuery, studyIdQuery: studyIdQuery};
}

function handleSubterm(
  searchSpace: string,
  operation: string,
  term: string,
  andQuery: BoolQuery
) {
  if (searchSpace !== 'Report' && searchSpace !== 'Impression') {
    throw new Error(`Unsupported search space: ${searchSpace}`);
  }
  if (
    operation !== 'contains substring' &&
    operation !== 'excludes substring' &&
    operation !== 'contains substring (no negations)'
  ) {
    throw new Error(`Unsupported operation: ${operation}`);
  }
  if (term.length === 0) {
    throw new Error('Empty search term');
  }

  if (searchSpace === 'Report') {
    switch (operation) {
      case 'contains substring':
        return andQuery.must(buildBaseQuery([], term));
      case 'excludes substring':
        return andQuery.mustNot(buildBaseQuery([], term));
      case 'contains substring (no negations)':
        return andQuery.must(buildBaseQuery(['report_no_negations'], term));
    }
  } else {
    const queryField =
      operation === 'contains substring (no negations)'
        ? 'report_no_negations'
        : 'report';
    const impressionQuery = spanNearQuery()
      .clauses([
        spanTermQuery(queryField, 'impression'),
        spanTermQuery(queryField, term),
      ])
      .slop(50) // XXXHACK: allow up to 50 words between the two terms
      .inOrder(true);
    switch (operation) {
      case 'contains substring':
        return andQuery.must(impressionQuery);
      case 'excludes substring':
        return andQuery.mustNot(impressionQuery);
      case 'contains substring (no negations)':
        return andQuery.must(impressionQuery);
    }
  }
}

export function buildElasticQuery(fields: TextField[]): object {
  const boolQuery = new BoolQuery();
  let hasAtLeastOneField = false;

  fields
    .map(
      (
        field // handle snomed terms
      ) =>
        _.isEmpty(field.snomedTerms)
          ? field
          : {
              ...field,
              value: field
                .snomedTerms!.map(term => `"${removeTextInParentheses(term)}"`)
                .join('|'),
            }
    )
    .filter(field => !_.isEmpty(field.value)) // filter out empty search boxes
    .forEach(field => {
      const {value, operation} = field;
      hasAtLeastOneField = true;

      const currentQuery = new BoolQuery();
      boolQuery.must(currentQuery);

      // split on any pipes for OR | Operations
      const terms = _.split(value, '|')
        .map(v => _.trim(v))
        .filter(v => !_.isEmpty(v));

      const orQuery = new BoolQuery();
      for (const term of terms) {
        // handle EXACT "" operations
        if (term.startsWith('"') && term.endsWith('"')) {
          orQuery.should(handleExactMatch(term, field));
        } else if (couldBeAnID(term)) {
          // handle ID searches
          const {patientIdQuery, studyIdQuery} = handleIDs(term);
          orQuery.should(patientIdQuery);
          orQuery.should(studyIdQuery);
        } else {
          const subTerms = term // split the words in the same term
            .split(/\s+/)
            .filter(term => _.trim(term).length > 0);
          let andQuery = new BoolQuery();
          for (const subTerm of subTerms) {
            andQuery = handleSubterm(
              field.reportType,
              operation,
              subTerm,
              andQuery
            ); // combine each word in a term with an AND
          }
          orQuery.should(andQuery); // Combine any piped | terms with an OR
        }
      }
      currentQuery.must(orQuery); // Add the OR query to the current field query
    });

  if (!hasAtLeastOneField) {
    boolQuery.must(new BoolQuery());
  }

  const body = boolQuery.toJSON();
  return body;
}

interface MinervaSearchFormProps {
  onSubmit: (query: Object) => void;
  queryState: TextField[];
  queryStateChange: (queryState: TextField[]) => void;
  autoSubmit?: boolean;
}

export type textFieldAutofillStateType = {
  textFieldID: string;
  expandedSnomedConcepts: string[];
};

export const MinervaSearchForm = ({
  onSubmit,
  queryStateChange,
  queryState,
  autoSubmit = false,
}: MinervaSearchFormProps) => {
  const [textFieldAutofillState, textFieldAutofillStateChange] =
    useState<textFieldAutofillStateType>();
  const http = useAxios();
  useEffect(() => {
    if (autoSubmit) {
      onSubmit(buildElasticQuery(queryState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSubmit]);

  const [isTyping, isTypingChange] = useState(false);
  const [hideSynonymsList, hideSynonymsListChange] = useState(false);
  const onTypingStop = () => {
    isTypingChange(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnTypingStop = useCallback(_.debounce(onTypingStop, 1000), []);

  const currentTextField = _.find(queryState, {
    id: textFieldAutofillState?.textFieldID,
  });
  const {
    data: snomedConceptSuggestions,
    isLoading: snomedConceptSuggestionsLoading,
    isError: snomedConceptSuggestionsIsError,
    isSuccess: snomedConceptSuggestionsIsSuccess,
  } = useQuery(
    ['snomedConceptSuggestion', _.trim(currentTextField?.value)],
    () => fetchSnomedConceptSuggestions(http, _.trim(currentTextField?.value)),
    {
      enabled: !isTyping && _.trim(currentTextField?.value) !== '',
      staleTime: Infinity,
      onError: () => _.noop,
    }
  );

  const addField = () => {
    queryStateChange([...queryState, newTextField()]);
  };

  const removeField = (id: string) => {
    queryStateChange(queryState.filter(field => field.id !== id));
  };

  const mutateSnomedTerms = (
    operation: 'add' | 'remove',
    existingTerms: string[],
    term: string
  ) => {
    if (operation === 'add') {
      if (!_.includes(existingTerms, term)) {
        existingTerms = [...existingTerms, term];
      }
    } else {
      _.remove(existingTerms, el => el === term);
    }
    return existingTerms;
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col gap-y-2">
        {queryState.map((textField, textFieldIndex) => (
          <div key={textField.id} className="grid grid-cols-12 w-full gap-x-4">
            <div className="col-span-2">
              <Select
                text={
                  _.find(textFieldReportOptions, {
                    value: textField.reportType,
                  })?.label || ''
                }
                value={textField.reportType}
                options={textFieldReportOptions}
                onSelect={(option: SelectOption<'Report' | 'Impression'>) => {
                  const updatedQueryState = queryState.map(field =>
                    field.id === textField.id
                      ? {
                          ...field,
                          value: textField.value,
                          reportType: option.value,
                        }
                      : field
                  );

                  queryStateChange(updatedQueryState);
                }}
                contained
              />
            </div>

            <div className="col-span-2">
              <Select
                text={
                  _.find(textFieldOperationOptions, {
                    value: textField.operation,
                  })?.label || ''
                }
                value={textField.operation}
                options={textFieldOperationOptions}
                onSelect={(option: SelectOption<string>) => {
                  const updatedQueryState = queryState.map(field =>
                    field.id === textField.id
                      ? {
                          ...field,
                          value: textField.value,
                          operation: option.value,
                        }
                      : field
                  );

                  queryStateChange(updatedQueryState);
                }}
                contained
              />
            </div>

            <div
              className={cx({
                'col-span-8': queryState.length <= 1,
                'col-span-7': queryState.length > 1,
              })}
            >
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (textFieldAutofillState?.textFieldID === textField.id) {
                    textFieldAutofillStateChange(undefined);
                  }
                }}
              >
                <input
                  type="text"
                  className="text-input w-full text-gray-700"
                  value={
                    textField.id === textFieldAutofillState?.textFieldID ||
                    _.isEmpty(textField.snomedTerms)
                      ? // focused or no concept selected
                        textField.value
                      : textField
                          .snomedTerms!.map(term => `"${term}"`)
                          .join(' | ') ?? ''
                  }
                  onChange={e => {
                    hideSynonymsListChange(false);
                    !isTyping && isTypingChange(true);
                    debouncedOnTypingStop();

                    queryStateChange(
                      queryState.map(field =>
                        field.id === textField.id
                          ? {
                              ...field,
                              value: e.target.value,
                            }
                          : field
                      )
                    );
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      hideSynonymsListChange(true);
                      onSubmit(buildElasticQuery(queryState));
                    }
                  }}
                  placeholder={
                    textFieldIndex === 0
                      ? 'Type a search term to search for studies from real-world medical institutions, e.g. "abdominal CTs pancreatic cancer"'
                      : 'Type additional search term'
                  }
                  key={textField.id}
                  onFocus={() => {
                    textFieldAutofillStateChange({
                      textFieldID: textField.id,
                      expandedSnomedConcepts: [],
                    });
                  }}
                />
                {!hideSynonymsList && (
                  <div className={cx('relative')}>
                    <div
                      className={cx(
                        'absolute min-w-min max-h-96 overflow-y-scroll mt-2 rounded-md shadow-lg bg-white ring-1 ring-gray-900 ring-opacity-5 z-30 m-b-5 break-words w-full p-6 space-y-4',
                        {
                          hidden:
                            textFieldAutofillState?.textFieldID !==
                              textField.id ||
                            (_.isEmpty(textField.snomedTerms) &&
                              (_.trim(currentTextField?.value) === '' ||
                                snomedConceptSuggestionsIsError)),
                        }
                      )}
                    >
                      {!_.isEmpty(_.trim(textField.value)) && (
                        <div>
                          {(snomedConceptSuggestionsLoading ||
                            (!snomedConceptSuggestionsIsSuccess &&
                              isTyping)) && (
                            <span className="text-gray-500">
                              Looking for suggestions...
                            </span>
                          )}
                          {snomedConceptSuggestions?.length === 0 && (
                            <span className="text-gray-500">
                              No suggestions for{' '}
                              <span className="italic">
                                &quot;{textField.value}&quot;
                              </span>
                            </span>
                          )}
                          {snomedConceptSuggestions?.map(
                            (snomedConcept, snomedConceptIndex) => {
                              const isExpanded = _.includes(
                                textFieldAutofillState?.expandedSnomedConcepts,
                                `${snomedConcept.conceptId}_${snomedConcept.term}`
                              );

                              return (
                                <div key={snomedConceptIndex}>
                                  {isExpanded ? (
                                    <button
                                      className="align-middle text-lg text-gray-500"
                                      onClick={() => {
                                        const expandedSnomedConcepts =
                                          textFieldAutofillState?.expandedSnomedConcepts ||
                                          [];
                                        _.remove(
                                          expandedSnomedConcepts,
                                          concept =>
                                            concept ===
                                            `${snomedConcept.conceptId}_${snomedConcept.term}`
                                        );
                                        textFieldAutofillStateChange({
                                          textFieldID: textField.id,
                                          expandedSnomedConcepts: [
                                            ...expandedSnomedConcepts,
                                          ],
                                        });
                                      }}
                                    >
                                      <HiChevronDown />
                                    </button>
                                  ) : (
                                    <button
                                      className="align-middle text-lg text-gray-500"
                                      onClick={() =>
                                        textFieldAutofillStateChange({
                                          textFieldID: textField.id,
                                          expandedSnomedConcepts: [
                                            ...(textFieldAutofillState?.expandedSnomedConcepts ||
                                              []),
                                            `${snomedConcept.conceptId}_${snomedConcept.term}`,
                                          ],
                                        })
                                      }
                                    >
                                      <HiChevronRight />
                                    </button>
                                  )}

                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    id={`snomed_term_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}`}
                                    checked={_.includes(
                                      textField.snomedTerms,
                                      snomedConcept.term
                                    )}
                                    onChange={e => {
                                      const newSnomedTags = mutateSnomedTerms(
                                        e.target.checked ? 'add' : 'remove',
                                        textField.snomedTerms || [],
                                        snomedConcept.term
                                      );
                                      // eslint-disable-next-line security/detect-object-injection
                                      queryState[textFieldIndex].snomedTerms =
                                        newSnomedTags;

                                      queryStateChange(_.cloneDeep(queryState));
                                    }}
                                  />
                                  <label
                                    htmlFor={`snomed_term_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}`}
                                    className="ml-2"
                                  >
                                    {snomedConcept.term}
                                  </label>

                                  {isExpanded && (
                                    <div className="ml-8 space-y-2 my-2">
                                      {!_.isEmpty(snomedConcept.synonyms) && (
                                        <div>
                                          <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {snomedConcept.synonyms.length === 1
                                              ? 'Snomed Synonym'
                                              : 'Snomed Synonyms'}
                                          </div>
                                          {snomedConcept.synonyms?.map(
                                            (
                                              synonymConcept,
                                              synonymConceptIndex
                                            ) => (
                                              <div key={synonymConceptIndex}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox-input"
                                                  id={`checkbox_snomed_synonym_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${synonymConcept.term}`}
                                                  checked={_.includes(
                                                    textField.snomedTerms,
                                                    synonymConcept.term
                                                  )}
                                                  onChange={e => {
                                                    const newSnomedTags =
                                                      mutateSnomedTerms(
                                                        e.target.checked
                                                          ? 'add'
                                                          : 'remove',
                                                        textField.snomedTerms ||
                                                          [],
                                                        synonymConcept.term
                                                      );
                                                    // eslint-disable-next-line security/detect-object-injection
                                                    queryState[
                                                      textFieldIndex
                                                    ].snomedTerms =
                                                      newSnomedTags;

                                                    queryStateChange(
                                                      _.cloneDeep(queryState)
                                                    );
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`checkbox_snomed_synonym_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${synonymConcept.term}`}
                                                  className="ml-2"
                                                >
                                                  {synonymConcept.term}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}

                                      {!_.isEmpty(snomedConcept.children) && (
                                        <div>
                                          <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {snomedConcept.children.length === 1
                                              ? 'Snomed Subcategory'
                                              : 'Snomed Subcategories'}
                                          </div>
                                          {snomedConcept.children?.map(
                                            (
                                              childConcept,
                                              childConceptIndex
                                            ) => (
                                              <div key={childConceptIndex}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox-input"
                                                  id={`checkbox_snomed_child_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${childConcept.term}`}
                                                  checked={_.includes(
                                                    textField.snomedTerms,
                                                    childConcept.term
                                                  )}
                                                  onChange={e => {
                                                    const newSnomedTags =
                                                      mutateSnomedTerms(
                                                        e.target.checked
                                                          ? 'add'
                                                          : 'remove',
                                                        textField.snomedTerms ||
                                                          [],
                                                        childConcept.term
                                                      );
                                                    // eslint-disable-next-line security/detect-object-injection
                                                    queryState[
                                                      textFieldIndex
                                                    ].snomedTerms =
                                                      newSnomedTags;

                                                    queryStateChange(
                                                      _.cloneDeep(queryState)
                                                    );
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`checkbox_snomed_child_${textField.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${childConcept.term}`}
                                                  className="ml-2"
                                                >
                                                  {childConcept.term}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}

                      {!_.isEmpty(textField.snomedTerms) && (
                        <div>
                          <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Selected
                          </div>
                          {textField.snomedTerms!.map(snomedTerm => (
                            <div key={snomedTerm}>
                              <input
                                type="checkbox"
                                className="checkbox-input"
                                id={`checkbox_selected_snomed_term_${textField.id}_${snomedTerm}`}
                                checked={_.includes(
                                  textField.snomedTerms,
                                  snomedTerm
                                )}
                                onChange={e => {
                                  const newSnomedTags = mutateSnomedTerms(
                                    e.target.checked ? 'add' : 'remove',
                                    textField.snomedTerms || [],
                                    snomedTerm
                                  );
                                  // eslint-disable-next-line security/detect-object-injection
                                  queryState[textFieldIndex].snomedTerms =
                                    newSnomedTags;

                                  queryStateChange(_.cloneDeep(queryState));
                                }}
                              />
                              <label
                                htmlFor={`checkbox_selected_snomed_term_${textField.id}_${snomedTerm}`}
                                className="ml-2"
                              >
                                {snomedTerm}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
            <button
              onClick={() => {
                trackEvent('CLICK_REMOVE_FIELD_BTN');
                removeField(textField.id);
              }}
              className={
                queryState.length <= 1
                  ? 'hidden'
                  : 'flex justify-center items-center'
              }
            >
              <HiOutlineXCircle className="h-6 w-6 text-red-500 center" />
            </button>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-12 w-full gap-x-4">
        <button
          onClick={() => {
            addField();
          }}
          className={cx(
            'btn-link w-full text-left font-medium text-sm justify-center col-span-2 pl-2',
            {
              hidden: queryState.length >= maxFields,
            }
          )}
        >
          Add search term
        </button>
        <div className="col-span-9"></div>
        <div className="flex flex-row-reverse gap-x-3">
          <button
            className="btn btn-primary w-full px-5 justify-center"
            data-cy="SearchForm_searchBtn"
            id="searchBtn"
            onClick={e => {
              e.preventDefault();
              onSubmit(buildElasticQuery(queryState));
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
