import React, {useState} from 'react';
import {HiOutlineArrowRight} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {Modal} from '../../core/layout/modal';
import {MenuDropdownSingle} from '../../core/components/menu';
import {
  updateStudiesTags,
  Tag as TagInterface,
  updateCachedTags,
} from '../../models/tags';
import {ToastMessage} from '../../core/components/toast';
import {CreateTagForm, Tag} from '../../components/tags';
import {queryToRequest, SearchQuery} from '../../models/search';
import {useAxios} from 'src/utils/http';

export const TagDropdown = ({
  tags,
  studyIDs = [],
  query,
  disabled = false,
  align,
  added,
  created,
}: {
  tags: TagInterface[];
  studyIDs: string[];
  query?: SearchQuery;
  disabled?: boolean;
  align?: 'left' | 'right';
  added?: (tag: TagInterface) => void;
  created?: (tagID: number) => void;
}) => {
  const api = useAxios();
  const [modalOpen, modalOpenChange] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const openModal = () => {
    modalOpenChange(true);
  };

  const closeModal = () => {
    modalOpenChange(false);
  };

  const createTagModal = () => {
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        className="max-w-lg"
      >
        <CreateTagForm
          created={(tagID: number) => {
            closeModal();
            updateStudiesTags(api, {
              study_ids: studyIDs,
              query: query && queryToRequest(query),
              updates: {add_tags: [tagID]},
            })
              .then(() => {
                toast(<ToastMessage title="Tag added" icon="success" />);
                studyIDs.forEach(studyID => {
                  queryClient.invalidateQueries(['report', studyID]);
                  queryClient.invalidateQueries(['tags']);
                });
                updateCachedTags(api, queryClient, studyIDs);
                created && created(tagID);
              })
              .catch(err => {
                const message =
                  err?.response?.data?.message ?? 'Error adding tag';
                toast(<ToastMessage title={message} icon="error" />);
              });
          }}
          cancelled={closeModal}
        />
      </Modal>
    );
  };

  return (
    <>
      <MenuDropdownSingle
        align={align}
        buttonText="Tag"
        buttonClassName="btn-xs"
        menuButtonDisabled={disabled}
        dividerIndexes={[tags.length - 1]}
        menuItems={[
          ...(tags.length
            ? tags.map(tag => {
                return {
                  itemText: () => <Tag tag={tag} />,
                  disabled: disabled,
                  onClick: () => {
                    updateStudiesTags(api, {
                      study_ids: studyIDs,
                      query: query && queryToRequest(query),
                      updates: {add_tags: [tag.tid]},
                    })
                      .then(() => {
                        toast(
                          <ToastMessage title="Tag added" icon="success" />
                        );
                        studyIDs.forEach(studyID => {
                          queryClient.invalidateQueries(['report', studyID]);
                        });
                        updateCachedTags(api, queryClient, studyIDs);

                        added && added(tag);
                      })
                      .catch(err => {
                        const message =
                          err?.response?.data?.message ?? 'Error adding tag';
                        toast(<ToastMessage title={message} icon="error" />);
                      });
                  },
                };
              })
            : [
                {
                  itemText: () => 'No available tags',
                  disabled: true,
                },
              ]),
          {
            itemText: () => 'Create new tag',
            disabled: disabled,
            onClick: () => openModal(),
          },
          {
            itemText: () => {
              return (
                <div className="flex flex-row flex-nowrap items-center">
                  Manage Tags <HiOutlineArrowRight className="ml-2" />
                </div>
              );
            },
            disabled: disabled,
            onClick: () => navigate('/tags'),
          },
        ]}
      />
      {createTagModal()}
    </>
  );
};
