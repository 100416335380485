import {Menu} from '@headlessui/react';
import {ReactNode} from 'react';
import cx from 'classnames';

export type MenuItemProps = {
  itemText: (itemProps?: {active: boolean; disabled: boolean}) => ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const MenuItem = ({itemText, disabled, onClick}: MenuItemProps) => {
  return (
    <Menu.Item disabled={disabled}>
      {item => (
        <button
          type="button"
          className={cx(
            item.active ? 'bg-primary text-white' : 'text-gray-700',
            {
              'opacity-50 cursor-not-allowed': disabled,
            },
            'group flex rounded-md items-center w-full p-2 text-sm'
          )}
          disabled={item.disabled}
          onClick={() => onClick && onClick()}
        >
          {itemText(item)}
        </button>
      )}
    </Menu.Item>
  );
};
