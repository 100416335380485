import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import {useHotkeys} from 'react-hotkeys-hook';
import {useQuery, useQueryClient} from 'react-query';

import {Loading} from '../../../core/components/loading';
import {ReportComponent} from '../../../components/report';
import {fetchDataset, DatasetStudy} from '../../../models/dataset';
import {fetchReport} from '../../../models/report';
import {Breadcrumbs} from '../../../core/components/breadcrumbs';
import {
  fetchDatasetDicomInfo,
  getDicomSeries,
} from '../../../models/dicom-viewer';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

interface DatasetReportNavigation {
  currentStudyId: string;
  prevStudyId?: string;
  nextStudyId?: string;
}

export const DatasetReportDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const api = useAxios();

  const [reportNavigation, reportNavigationChange] =
    useState<DatasetReportNavigation>();

  const q = useParams<{datasetId?: string; studyId?: string}>();
  const datasetId = _.isFinite(_.toNumber(q.datasetId))
    ? _.toNumber(q.datasetId)
    : undefined;
  const studyId = _.isString(q.studyId) ? q.studyId : undefined;

  const setupNavigation = (studyId: string, studies: DatasetStudy[]) => {
    const studyIndex = _.findIndex(studies, {
      studyId,
    });

    let prevStudyId: string | undefined;
    let nextStudyId: string | undefined;
    if (studyIndex !== -1) {
      if (studyIndex > 0) {
        prevStudyId = studies[studyIndex - 1].studyId;
      }
      if (studyIndex < studies.length - 1) {
        nextStudyId = studies[studyIndex + 1].studyId;
      }
    }
    reportNavigationChange({
      currentStudyId: studyId,
      prevStudyId,
      nextStudyId,
    });
  };

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', studyId],
    () => {
      return fetchReport(api, studyId as string);
    },
    {
      enabled: _.isString(studyId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: datasetWithStudies, error: datasetError} = useQuery(
    ['dataset', datasetId],
    () => {
      return fetchDataset(api, datasetId!);
    },
    {
      enabled: !_.isNil(datasetId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: datasetDicomInfo} = useQuery(
    ['datasetDicom', datasetId],
    () => {
      return fetchDatasetDicomInfo(api, datasetId!);
    },
    {
      enabled: !_.isNil(datasetId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const prevButtonPressed = () => {
    if (!_.isNil(reportNavigation?.prevStudyId)) {
      navigate(
        `/datasets/${datasetId}/report/${reportNavigation!.prevStudyId}`
      );
    }
  };
  const nextButtonPressed = () => {
    if (!_.isNil(reportNavigation?.nextStudyId)) {
      navigate(
        `/datasets/${datasetId}/report/${reportNavigation!.nextStudyId}`
      );
    }
  };

  useHotkeys('left', prevButtonPressed, [reportNavigation]);
  useHotkeys('right', nextButtonPressed, [reportNavigation]);

  useEffect(() => {
    // Make sure that dataset is valid
    if (_.isNil(datasetId) || !_.isNil(datasetError)) {
      // @todo: Handle 404
    } else if (_.isNil(studyId) || !_.isNil(reportError)) {
      navigate(`/datasets/${datasetId}`);
    }

    // Setup navigation
    if (
      !_.isNil(datasetWithStudies) &&
      !_.isNil(report) &&
      reportNavigation?.currentStudyId !== report.studyId
    ) {
      setupNavigation(report.studyId!, datasetWithStudies.studies);
    }
  }, [
    datasetWithStudies,
    datasetError,
    datasetId,
    navigate,
    report,
    reportError,
    reportNavigation?.currentStudyId,
    studyId,
  ]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Report Details</title>
      </Helmet>

      <ApplicationShell>
        <div className="mb-5">
          <Breadcrumbs
            links={[
              {name: 'Your Datasets', href: '/datasets'},
              {
                name: datasetWithStudies?.dataset.name ?? 'Dataset',
                href: `/datasets/${datasetId}`,
              },
              {
                name:
                  report?.reportTitle ?? report?.studyId.slice(-8) ?? 'Report',
                current: true,
              },
            ]}
          />
        </div>

        {reportLoading && <Loading />}
        {report && (
          <ReportComponent
            report={report}
            reportChanged={() => {
              queryClient.refetchQueries(['dataset'], {exact: true});
              queryClient.refetchQueries(['dataset', datasetId]);
            }}
            PHIReported={() => {
              queryClient.removeQueries(['search']);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['report', report.studyId]);
              navigate({
                pathname: `/datasets/${datasetId}`,
              });
            }}
            showPrevButton={!_.isNil(reportNavigation?.prevStudyId)}
            showNextButton={!_.isNil(reportNavigation?.nextStudyId)}
            onPrevButtonClick={() => prevButtonPressed()}
            onNextButtonClick={() => nextButtonPressed()}
            dicomSeries={getDicomSeries(report.studyId, datasetDicomInfo)}
          />
        )}
      </ApplicationShell>
    </>
  );
};
