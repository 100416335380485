import {useForm} from 'react-hook-form';
import cx from 'classnames';
import {Modal} from '../../core/layout/modal';

interface CreateCustomNumberReportsFields {
  num: string;
}

export const SelectCustomNumberReportsModal = ({
  closeModal,
  selectReports,
}: {
  closeModal: () => void;
  selectReports: (num: number) => void;
  filters: any;
}) => {
  const {register, handleSubmit, errors, setError} =
    useForm<CreateCustomNumberReportsFields>();

  const onSubmit = handleSubmit((form: CreateCustomNumberReportsFields) => {
    if (parseInt(form.num) <= 5000 && parseInt(form.num) > 0) {
      selectReports(parseInt(form.num));
      closeModal();
    } else {
      setError('num', {
        type: 'manual',
        message: 'Please enter a number between 1 and 5000',
      });
    }
  });

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal()}
      className="max-w-lg"
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="text-lg mb-3 mr-6">Enter Number to Select</div>
        <div className="mb-3">
          <label htmlFor="num" className="input-label">
            Number of reports to select
          </label>
          <input
            name="num"
            type="number"
            className={cx('text-input w-full mt-2', {
              'text-input-error': !!errors.num,
            })}
            autoComplete="off"
            ref={register({
              required: true,
            })}
          />
          {errors.num && <p className="text-red-600">{errors.num.message}</p>}
        </div>
        <div className="flex flex-row-reverse justify-between">
          <button
            className="btn btn-primary max-h-8 ml-2 min-w-max"
            type="submit"
            disabled={!!errors.num}
          >
            Select Reports
          </button>
        </div>
      </form>
    </Modal>
  );
};
