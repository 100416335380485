import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

export const Login = () => {
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const isLiveTest = process.env.REACT_APP_LIVE_TEST !== 'true';
    if (!isLoading && isLiveTest) {
      if (!isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: '/',
          },
        });
      } else {
        navigate('/');
      }
    }
  }, [isAuthenticated, isLoading]);

  return (
    <div>
      <button
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: '/',
            },
          })
        }
      >
        Login
      </button>
    </div>
  );
};
