/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useMemo} from 'react';

import _ from 'lodash';
import {DateTime} from 'luxon';

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
  withSearch,
} from '@elastic/react-search-ui';
import type {SearchDriverOptions} from '@elastic/search-ui';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

import {
  buildSortOptionsFromConfig,
  getFacetFields,
} from '../../../config/config-helper';
import {Link} from 'react-router-dom';
import {
  ReportSelectionEventType,
  isAnyReportSelected,
  isReportSelected,
  useReportSelection,
} from '../../../hooks/report-selection-provider';
import {trackEvent} from '../../../utils/tracking';
import {AddToDatasetDropdown} from '../../../components/dataset';
import {TagDropdown} from '../../../components/tags';
import {useQuery} from 'react-query';
import {getAvailableTags} from '../../../models/tags';
import {Loading} from '../../../core/components/loading';
import {formatNumber} from '../../../utils/strings';
import {EmbedVideo} from '../../../core/components/embed-video';
import {HiOutlineExternalLink} from 'react-icons/hi';
import {Card} from '../../../core/layout/card';
import {DicomThumbnail} from '../../../core/components/study-thumbnail/study-thumbnail';
import {
  premadeDatasets,
  demoVideos,
  formatFieldLabel,
} from '../minerva-search/minerva-search';
import {useAxios} from 'src/utils/http';

const ClearFilters = ({filters, clearFilters}: any) => {
  return (
    <button
      onClick={() => clearFilters()}
      disabled={filters.length === 0}
      className="btn btn-secondary my-4"
    >
      Clear {filters.length} Filters
    </button>
  );
};

const ClearFilterButton = withSearch(({filters, clearFilters}) => ({
  filters,
  clearFilters,
}))(ClearFilters);

export const MinervaImageSearch = ({config}: {config: SearchDriverOptions}) => {
  const api = useAxios();
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();

  const studiesSelected = isAnyReportSelected(reportSelectionState);

  const {data: tags, isLoading: tagsLoading} = useQuery(
    ['tags'],
    () => getAvailableTags(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          results,
          facets,
          rawResponse,
          isLoading,
          resultSearchTerm,
        }) => ({
          wasSearched,
          results,
          facets,
          rawResponse,
          isLoading,
          resultSearchTerm,
        })}
      >
        {({
          wasSearched,
          results,
          facets,
          rawResponse,
          isLoading,
          resultSearchTerm,
        }) => {
          const pageAllSelected = _.every(results, result =>
            isReportSelected(reportSelectionState, result['study_id']['raw'])
          );
          const pageSomeSelected =
            pageAllSelected ||
            _.some(results, result =>
              isReportSelected(reportSelectionState, result['study_id']['raw'])
            );

          return (
            <div>
              <ErrorBoundary>
                <div className="flex flex-col gap-y-4 p-6">
                  <div className="">
                    <SearchBox
                      autocompleteSuggestions={false}
                      searchAsYouType={false}
                      inputProps={{
                        required: true,
                        maxLength: 128,
                        placeholder:
                          'Type a search query to start searching for studies, e.g. "abdominal CTs pancreatic cancer"',
                      }}
                    />
                  </div>

                  {isLoading && (
                    <div className="fixed bottom-0 right-0 bg-black">
                      <Loading className="block p-2 text-white" />
                    </div>
                  )}

                  {!isLoading &&
                    wasSearched &&
                    results.length === 0 &&
                    `No search results found for "${resultSearchTerm}"`}
                  {wasSearched && results.length > 0 && (
                    <>
                      <div className="flex justify-between">
                        <div>{`Showing ${
                          (_.get(rawResponse, 'rawInfo.meta.page.current') -
                            1) *
                            _.get(rawResponse, 'rawInfo.meta.page.size') +
                          1
                        } - ${Math.min(
                          _.find(_.get(facets, 'orig_lang[0].data'), {
                            value: 'en',
                          })?.count,
                          _.get(rawResponse, 'rawInfo.meta.page.current') *
                            _.get(rawResponse, 'rawInfo.meta.page.size')
                        )} out of ${formatNumber(
                          _.find(_.get(facets, 'orig_lang[0].data'), {
                            value: 'en',
                          })?.count
                        )} for "${resultSearchTerm}"`}</div>
                        <ResultsPerPage options={[50, 100]} />
                      </div>

                      <div className="flex gap-x-4 max-w-screen">
                        <div className="flex flex-col">
                          <Sorting
                            label={'Sort by'}
                            sortOptions={buildSortOptionsFromConfig()}
                            className="w-72"
                          />

                          <ClearFilterButton />

                          {getFacetFields().map((field: any) => {
                            const label = formatFieldLabel(field);
                            return (
                              <Facet
                                key={field}
                                field={field}
                                label={label}
                                filterType="any"
                                isFilterable
                              />
                            );
                          })}
                        </div>

                        <div className="overflow-hidden">
                          <div className="mb-4 flex items-center gap-x-2">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                data-tour="results-select-all"
                                className="checkbox-input ml-6 mr-1"
                                checked={pageAllSelected}
                                ref={el =>
                                  el && (el.indeterminate = pageSomeSelected)
                                }
                                data-cy="Results_selectAllInput"
                                onChange={e => {
                                  const checked = e.target.checked;

                                  trackEvent(
                                    checked
                                      ? 'SEARCH_SELECT_PAGE'
                                      : 'SEARCH_UNSELECT_PAGE'
                                  );

                                  reportSelectionDispatch({
                                    type: e.target.checked
                                      ? ReportSelectionEventType.SELECT
                                      : ReportSelectionEventType.UNSELECT,
                                    payload: _.map(
                                      results,
                                      result => result['study_id']['raw']
                                    ),
                                  });
                                }}
                              />
                              <span className="ml-2 font-normal">{`${reportSelectionState.add.ids.size} Selected`}</span>
                            </div>

                            <AddToDatasetDropdown
                              disabled={!studiesSelected}
                              reportSelectionState={reportSelectionState}
                            />
                            <TagDropdown
                              tags={userTags}
                              studyIDs={Array.from(
                                reportSelectionState.add.ids
                              )}
                              align="left"
                              disabled={!studiesSelected || tagsLoading}
                            />
                          </div>

                          <div className="grid grid-cols-4 gap-2">
                            {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              results.map((result: any) => {
                                return (
                                  <Card
                                    key={result['study_id']['raw']}
                                    className="border-b text-sm minerva-search-results"
                                  >
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        checked={isReportSelected(
                                          reportSelectionState,
                                          result['study_id']['raw']
                                        )}
                                        onChange={e => {
                                          const checked = e.target.checked;

                                          reportSelectionDispatch({
                                            type: checked
                                              ? ReportSelectionEventType.SELECT
                                              : ReportSelectionEventType.UNSELECT,
                                            payload: [
                                              result['study_id']['raw'],
                                            ],
                                          });
                                        }}
                                      />
                                    </div>
                                    <DicomThumbnail
                                      studyID={result['study_id']['raw']}
                                    />
                                    <dl>
                                      <dt>Study ID</dt>
                                      <dd>
                                        <Link
                                          to={`/study/${result['study_id']['raw']}`}
                                          className="link"
                                        >
                                          {result['study_id']['raw']}
                                        </Link>
                                      </dd>

                                      <dt>Patient ID</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['patient_id']['snippet'],
                                        }}
                                      />

                                      <dt>Title</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['report_title']['snippet'] ||
                                            result['study_id']['raw'],
                                        }}
                                      />

                                      <dt>Modality</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html: result['modality']['snippet'],
                                        }}
                                      />

                                      <dt>Body Part</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['body_part']['snippet'],
                                        }}
                                      />

                                      <dt>Exam Date</dt>
                                      <dd>
                                        {_.trim(result['exam_date']['raw']) ===
                                        ''
                                          ? ''
                                          : DateTime.fromISO(
                                              result['exam_date']['raw']
                                            ).toLocaleString(DateTime.DATE_MED)}
                                      </dd>

                                      <dt>manufacturer</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['manufacturer']['snippet'],
                                        }}
                                      />

                                      <dt>Patient age</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['patient_age']['snippet'],
                                        }}
                                      />

                                      <dt>Patient Sex</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['patient_sex']['snippet'],
                                        }}
                                      />

                                      <dt>Model</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html: result['model']['snippet'],
                                        }}
                                      />

                                      <dt>Source Location</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            result['source_location'][
                                              'snippet'
                                            ],
                                        }}
                                      />

                                      <dt>Report</dt>
                                      <dd
                                        dangerouslySetInnerHTML={{
                                          __html: result['report']['snippet'],
                                        }}
                                      />
                                    </dl>
                                  </Card>
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {!isLoading && wasSearched && (
                  <div className="flex justify-center bottom-0 w-full bg-white p-4 border-t">
                    <Paging />
                  </div>
                )}
                {!isLoading && !wasSearched && (
                  <div className="space-y-2 text-gray-900 p-6">
                    <div className="space-y-2 text-gray-900">
                      <div>
                        <div className="text-xl mb-2 font-medium">
                          Datasets ready to purchase today
                        </div>
                        <div className="text-md text-gray-900">
                          These pre-made datasets can be purchased as is. Click
                          on an option for details.
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
                        {premadeDatasets.map((dataset, i) => {
                          return (
                            <Card key={i}>
                              <div
                                key={i}
                                className="flex flex-col gap-y-1 text-sm"
                              >
                                <a
                                  className="link font-sm"
                                  href={dataset.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={() =>
                                    trackEvent('CLICK_OPEN_PREMADE_DATASET', {
                                      label: dataset.label,
                                      url: dataset.url,
                                    })
                                  }
                                >
                                  <div className="flex flex-row justify-between items-center">
                                    {dataset.label}
                                    <div className="mb-auto">
                                      <HiOutlineExternalLink className="w-5 h-5 inline-block ml-1" />
                                    </div>
                                  </div>
                                </a>
                                <div className="text-gray-500 text-xs">
                                  {dataset.bodyText}
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </div>

                    <div className="text-xl mb-2 font-medium">Demo videos</div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
                      {demoVideos.map((demo, i) => {
                        return (
                          <div key={i} className="flex flex-col">
                            <div className="text-md text-gray-900 pb-2">
                              {demo.label}
                            </div>
                            <div className="flex flex-col gap-y-1 text-smborder border-transparent rounded-md shadow-sm border-gray-300 z-10 overflow-hidden">
                              <EmbedVideo src={demo.embed} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};
