import _ from 'lodash';

import {trackEvent} from '../utils/tracking';
import {Report, responseToReport, ReportResponse} from './report';
import type {
  APIConnector,
  FieldConfiguration,
  Filter,
  QueryConfig,
  RequestState,
} from '@elastic/search-ui';
import {processElasticSearchBody} from '../components/save-search/save-search-card';
import {SearchRequest} from '@segmed/search-ui-elasticsearch-connector';
import {AxiosInstance} from 'axios';

export interface Keyword {
  area: string;
  operator: string;
  text: string;
  booloperator: string;
}

export interface SearchQuery {
  modality: string[];
  bodyArea: string[];
  sourceLocation: string[];
  keywords: Keyword[];
  patients: string;
  additionalPatientStudies: boolean;
  isPatientSearch: boolean;
  vendor: string[];
  gender: string[];
  ethnicity: string[];
  race: string[];
  sliceThickness: number[];
  age: number[];
}

export interface SearchRequestQuery {
  booloperators: string[];
  columns: string[];
  operators: string[];
  searchterms: string[];
  areas: string[];
  modalities: string[];
  bodyparts: string[];
  datapartners: string[];
  patients: string[];
  additional_patient_studies: boolean;
  is_patient_search: boolean;
  vendor: string[];
  gender: string[];
  ethnicity: string[];
  race: string[];
  slice_thickness: number[];
  age: number[];
}

export interface SearchResult {
  reports: Report[];
  totalCount: number;
  page: number;
  totalPages: number;
}

export interface PatientSearchPatient {
  patientId: string;
  studyCount: number;
  studies: Report[];
  additionalStudies: Report[];
}

export interface PatientSearchResult {
  searchResults: PatientSearchPatient[];
  totalPatients: number;
  totalStudies: number;
  totalAdditionalStudies: number;
  page: number;
  totalPages: number;
}

export interface Modality {
  modality: string;
  label: string;
}

export interface SourceLocation {
  datapartner: string;
  label: string;
  status: string;
}

export interface BodyPartCategory {
  bodyPart: string;
  section: string;
  label: string;
}

export interface BodyPartSection {
  section: string;
  label: string;
}

export enum SourceLocationStatus {
  DOWN = 'DOWN',
  UP = 'UP',
  UNKNOWN = 'UNKNOWN',
}

export const queryToRequest = (query: SearchQuery): SearchRequestQuery => {
  return {
    booloperators: query.keywords.map(k => k.booloperator),
    columns: query.keywords.map(() => 'report'),
    operators: query.keywords.map(k => k.operator),
    searchterms: query.keywords.map(k => k.text),
    areas: query.keywords.map(k => k.area),
    modalities: query.modality,
    bodyparts: query.bodyArea,
    datapartners: query.sourceLocation,
    patients: query.patients
      .split(',')
      .map(patientId => _.trim(patientId))
      .filter(patientId => !_.isEmpty(patientId)),
    additional_patient_studies: query.additionalPatientStudies,
    is_patient_search: query.isPatientSearch,
    vendor: query.vendor,
    gender: query.gender,
    ethnicity: query.ethnicity,
    race: query.race,
    slice_thickness: [
      query.sliceThickness[0] ?? 0,
      query.sliceThickness[1] ?? 0,
    ],
    age: [query.age[0] ?? 0, query.age[1] ?? 0],
  };
};

export const fetchSearchResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('SEARCH', {query, page});

  return http.post('/v1/search', request).then(response => {
    const {data} = response;
    const {page, search_results, total_count, total_pages} = data;

    const reports: Report[] = _.map(search_results, (r: ReportResponse) =>
      responseToReport(r)
    );

    const searchResult: SearchResult = {
      reports: reports,
      totalCount: total_count,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchSearchDemogResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('SEARCH_DEMOG', {query, page});

  return http.post('/v1/search_demog', request).then(response => {
    const {data} = response;
    const {page, search_results, total_count, total_pages} = data;

    const reports: Report[] = _.map(search_results, (r: ReportResponse) =>
      responseToReport(r)
    );

    const searchResult: SearchResult = {
      reports: reports,
      totalCount: total_count,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchPatientSearchResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('PATIENT_SEARCH', {query, page});

  return http.post('/v1/patient_search', request).then(response => {
    const {data} = response;
    const {
      search_results,
      total_patients,
      total_studies,
      total_additional_studies,
      total_pages,
      page,
    } = data;

    const patients: PatientSearchPatient[] = _.map(
      search_results,
      (p: {
        patient_id: string;
        study_count: number;
        studies: ReportResponse[];
        additional_studies: ReportResponse[];
      }) => {
        const reports: Report[] = _.map(p.studies, s => responseToReport(s));
        const additionalReports: Report[] = _.map(p.additional_studies, s =>
          responseToReport(s)
        );
        return {
          patientId: p.patient_id,
          studyCount: p.study_count,
          studies: reports,
          additionalStudies: additionalReports,
        };
      }
    );

    const searchResult: PatientSearchResult = {
      searchResults: patients,
      totalPatients: total_patients,
      totalStudies: total_studies,
      totalAdditionalStudies: total_additional_studies,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchModalities = (http: AxiosInstance) => {
  return http.get('/v1/categories/modalities').then(response => {
    const {data} = response;
    const modalityOptions: Modality[] = data;
    return modalityOptions;
  });
};

export const fetchSourceLocations = (http: AxiosInstance) => {
  return http.get('/v1/categories/datapartners').then(response => {
    const {data} = response;
    const locationOptions: SourceLocation[] = data;
    return locationOptions;
  });
};

export const fetchBodyParts = (http: AxiosInstance) => {
  return http.get('/v1/categories/bodyparts').then(response => {
    const {data} = response;
    const sections: BodyPartSection[] = data.sections;
    const categories: BodyPartCategory[] = _.map(
      data.categories,
      categoryResp => ({
        bodyPart: categoryResp.body_part,
        section: categoryResp.section,
        label: categoryResp.label,
      })
    );
    return {sections, categories};
  });
};

export const fetchAllowedModalities = (http: AxiosInstance) => {
  return http.get('/v1/categories/modalities/allowed').then(response => {
    const {data} = response;
    const allowedModalities: string[] = data;
    return allowedModalities;
  });
};

export const fetchAllowedDataPartners = (http: AxiosInstance) => {
  return http.get('/v1/categories/datapartners/allowed').then(response => {
    const {data} = response;
    const allowedDataPartners: string[] = data;
    return allowedDataPartners;
  });
};

const formatFilters = (filters: {field: string; values: any[]}[]): Filter[] => {
  const formattedFilters: Filter[] = [];
  for (const filter of filters) {
    formattedFilters.push({
      field: filter.field,
      type: 'any',
      values: filter.values,
    });
  }
  return formattedFilters;
};

export const formatFiltersAppSearch = (filters: any) => {
  const formattedFilters: any = {all: []};
  for (const filter of filters) {
    formattedFilters.all.push({
      any: [{[`${filter.field}`]: filter.values}],
    });
  }
  return formattedFilters;
};

export const fetchAllStudiesForListOfPatientIDs = ({
  patient_ids,
  connector,
  resultFields,
}: {
  patient_ids: string[];
  connector: APIConnector;
  resultFields: Record<string, FieldConfiguration>;
}) => {
  const req: RequestState = {
    searchTerm: '',
    filters: formatFilters([
      {field: 'patient_id.keyword', values: patient_ids},
    ]),
    resultsPerPage: 10000,
  };

  const queryConfig: QueryConfig = {
    ...req,
    facets: {},
    result_fields: resultFields,
  };
  return connector.onSearch(req, queryConfig);
};

export const extractUniquePatientCount = (minervaResponse: any) => {
  return minervaResponse?.aggregations?.facet_bucket_all?.unique_patient_count
    ?.value;
};

function removeSizeAndFrom(elasticRequestBody: string): string {
  let jsonObj;
  // Check if the input is a string and a valid JSON
  if (typeof elasticRequestBody === 'string') {
    try {
      jsonObj = JSON.parse(elasticRequestBody);
    } catch (error) {
      throw new Error(`Invalid JSON string: ${elasticRequestBody}`);
    }
  } else if (typeof elasticRequestBody === 'object') {
    jsonObj = elasticRequestBody; // Assuming it's already a JSON object
  } else {
    throw new Error(
      `Unexpected input type for removeSizeAndFrom: ${typeof elasticRequestBody}`
    );
  }
  if (jsonObj && typeof jsonObj === 'object') {
    if (jsonObj.from) {
      delete jsonObj.from;
    }
    if (jsonObj.size) {
      delete jsonObj.size;
    }
  }
  return jsonObj;
}
// saved searches
export const saveSearch = (
  http: AxiosInstance,
  user: number,
  currentURL: string,
  studyCount: number,
  lastRequestBody: any
) => {
  const requestBody = {
    url: currentURL,
    userid: user,
    number_of_results_saved: studyCount,
    elastic_search_body: removeSizeAndFrom(JSON.stringify(lastRequestBody)),
  };
  return http.post('v1/saved/searches', requestBody);
};

export const unsaveSearch = (http: AxiosInstance, ssId: number) => {
  const requestBody = {
    ss_id: ssId,
  };
  return http.delete('v1/saved/searches', {data: requestBody});
};

export const checkSearchSaved = (
  http: AxiosInstance,
  user: number,
  lastRequestBody: string
) => {
  const requestBody = {
    user: user,
    elastic_search_body: removeSizeAndFrom(lastRequestBody),
  };
  return http
    .post('v1/saved/searches/check', requestBody)
    .then(response => {
      return response.data as {
        ss_id: number;
      };
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        return undefined; // Search is not saved
      } else {
        throw error;
      }
    });
};

export interface SavedSearches {
  ssId: number;
  userId: number;
  url: string;
  numberOfResultsSaved: number;
  currentNumberOfResults: number;
  elasticSearchBody: SearchRequest;
  createdAt: string;
}

interface SavedSearchesResponse {
  ss_id: number;
  userid: number;
  url: string;
  number_of_results_saved: number;
  current_number_of_results: number;
  elastic_search_body: string;
  created_at: string;
}

export const responseToSavedSearches = (
  savedSearchResp: SavedSearchesResponse
) => {
  const savedSearch: SavedSearches = {
    ssId: savedSearchResp.ss_id,
    userId: savedSearchResp.userid,
    url: savedSearchResp.url,
    numberOfResultsSaved: savedSearchResp.number_of_results_saved,
    currentNumberOfResults: savedSearchResp.current_number_of_results,
    elasticSearchBody: JSON.parse(savedSearchResp.elastic_search_body),
    createdAt: savedSearchResp.created_at,
  };

  return savedSearch;
};

export const fetchAllSavedSearches = (http: AxiosInstance) => {
  return http.get('/v1/saved/searches/getall').then(response => {
    const {data} = response;

    const savedSearches = _.map(data, savedSearchResp =>
      responseToSavedSearches(savedSearchResp)
    );

    return savedSearches;
  });
};

export const highlightSearchterm = (searchTerms: Set<string>, text: string) => {
  let highlightedText = text;

  // Utility function to trim special characters from the beginning and end of a string
  const trimSpecialChars = (string: string) => {
    return string.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '');
  };

  // Utility function to escape special regex characters
  const escapeRegexChars = (string: string) => {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Convert the Set to an Array, escape each term, and then filter
  Array.from(searchTerms)
    .map(trimSpecialChars)
    .map(escapeRegexChars)
    .filter(term => term && term.trim() !== '')
    .forEach(searchTerm => {
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(searchTerm, 'gi');
      highlightedText = highlightedText?.replace(
        regex,
        match => `<em>${match}</em>`
      );
    });

  return highlightedText;
};

export const extractSearchTerms = (
  elasticSearchBody: SearchRequest
): Set<string> => {
  elasticSearchBody = _.cloneDeep(elasticSearchBody);

  const uniqueQueries = new Set<string>();

  const elasticSearchBodyObj = removeAggsField(elasticSearchBody) as any;

  // recursively get all query values
  const getQueryValues = (obj: any) => {
    const isArray = _.isArray(obj);
    if (isArray) {
      obj.forEach((item: any) => {
        getQueryValues(item);
      });
    } else {
      for (const key in obj) {
        // eslint-disable-next-line security/detect-object-injection
        if (typeof obj[key] === 'object') {
          // eslint-disable-next-line security/detect-object-injection
          getQueryValues(obj[key]);
        } else if (
          // eslint-disable-next-line security/detect-object-injection
          typeof obj[key] === 'string' &&
          key === 'query'
        ) {
          // eslint-disable-next-line security/detect-object-injection
          uniqueQueries.add(obj[key]);
        }
      }
    }
  };

  if (!_.isEmpty(elasticSearchBodyObj?.query)) {
    getQueryValues(elasticSearchBodyObj?.query);
  }

  return uniqueQueries;
};

// Function to remove the 'aggs' field from the JSON string, because the json is too long to process all and have the "query" field
export function removeAggsField(jsonObj: SearchRequest) {
  try {
    // Check and remove the 'aggs' field if it exists
    if (jsonObj.aggs) {
      delete jsonObj.aggs;
    }

    return jsonObj;
  } catch (error) {
    return jsonObj; // Return the original string in case of error
  }
}

export function trackSearchEvent(
  requestBody: any,
  groupPatientIDs: boolean | undefined,
  sortPatientIDsBy: boolean | undefined,
  resultCount: number,
  duration: number // New parameter
) {
  const [queries, filters, operators] = processElasticSearchBody(requestBody);
  const searchEvent = {
    queries: Array.from(queries),
    patientSearch: {
      isActive: groupPatientIDs,
      sortBy: sortPatientIDsBy,
    },
    filters: filters,
    operators: operators,
    resultCount: resultCount,
    duration: duration, // Include duration in the event
  };
  if (searchEvent.queries.length || groupPatientIDs || filters.length) {
    trackEvent('SEARCH', searchEvent);
  }
}
