import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import _ from 'lodash';
import {toast} from 'react-toastify';
import cx from 'classnames';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {HiOutlineUserCircle, HiOutlineDocumentText} from 'react-icons/hi';

import {useAuth} from '../../../hooks/auth';
import {changeSourceIPs, fetchCredentials} from '../../../models/auth';
import {Modal} from '../../../core/layout/modal';
import {ToastMessage} from '../../../core/components/toast';
import {Loading} from '../../../core/components/loading';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

interface IPEditForm {
  sourceIPs: string;
}

export const AccountSettings = () => {
  const {authState} = useAuth();
  const queryClient = useQueryClient();
  const api = useAxios();

  const [credentialsHidden, credentialsHiddenChange] = useState<boolean>(true);

  const [sourceIPEditModalOpen, sourceIPEditModalOpenChange] = useState(false);
  const {
    register: sourceIPRegister,
    handleSubmit: sourceIPHandleSubmit,
    errors: sourceIPErrors,
    reset: sourceIPReset,
  } = useForm<IPEditForm>();

  const {data: credentials, isLoading: credentialsLoading} = useQuery(
    ['credentials', authState.profile?.email],
    () => fetchCredentials(api),
    {
      enabled: authState.loaded,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const changeSourceIPsMut = useMutation(
    (ips: string[] | 'current') => changeSourceIPs(api, ips),
    {
      onSuccess: response => {
        const {data} = response;
        const {message} = data;

        toast(<ToastMessage title={message} icon="success" />);

        queryClient.removeQueries(['credentials', authState.profile?.email]);
        sourceIPEditModalOpenChange(false);
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error updating IP Addresses';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const onSourceIPSubmit = sourceIPHandleSubmit((form: IPEditForm) => {
    const sourceIPs = (form.sourceIPs || '')
      .split(',')
      .map(ip => _.trim(ip))
      .filter(ip => !_.isEmpty(ip));

    return changeSourceIPsMut.mutate(sourceIPs);
  });

  const renderCredentials = () => {
    return (
      <div>
        <div className="text-xs text-gray-700 mb-4">
          Encrypted AWS S3 buckets can be used to deliver DICOM files. Please
          find instructions and your credentials here.
        </div>

        <a
          href={
            authState.profile?.userType === 'data_partner'
              ? 'https://segmed.notion.site/Segmed-Data-Transfer-Process-Data-Partners-15204e44377f490c938fa9dc7bb2767c'
              : 'https://segmed.notion.site/Segmed-Data-Transfer-Process-c62c77a17fde4ff0b65d624bdc8f81d3'
          }
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:text-blue-400 mt-2 text-sm block mb-4"
        >
          <HiOutlineDocumentText className="w-5 h-5 inline-block mr-1" />
          Data Transfer Instructions
        </a>

        <div className="text-lg">
          Credentials
          <div className="inline-block">
            <button
              onClick={() => credentialsHiddenChange(!credentialsHidden)}
              className="btn btn-link inline-block text-xs"
            >
              {credentialsHidden ? 'Show' : 'Hide'}
            </button>
          </div>
        </div>

        {!_.isNil(credentials) ? (
          <div className="text-xs text-gray-700">
            <div className="mb-1">
              <span className="font-semibold">Access Key ID</span>
              <div>
                {credentialsHidden ? '∗∗∗∗∗∗∗∗∗∗' : credentials?.S3Login}
              </div>
            </div>
            <div className="mb-1">
              <span className="font-semibold">Secret Access Key</span>
              <div>
                {credentialsHidden ? '∗∗∗∗∗∗∗∗∗∗' : credentials?.S3Pass}
              </div>
            </div>
            <div className="mb-1">
              <span className="font-semibold">Directory</span>
              <div>{credentials?.S3Directory}</div>
            </div>
          </div>
        ) : credentialsLoading ? (
          <Loading />
        ) : (
          <div className="text-gray-400 italic text-center">
            Error loading credentials
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Account Settings</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        {!_.isNil(credentials) && (
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav>
                  <a
                    className="bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    aria-current="page"
                  >
                    <HiOutlineUserCircle className="text-blue-500 group-hover:text-blue-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                    <span className="truncate">Profile</span>
                  </a>
                </nav>
              </aside>

              <div className="divide-y divide-gray-200 lg:col-span-9">
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h2 className="text-lg leading-6 font-medium">Profile</h2>
                  </div>
                </div>

                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Email
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span className="flex-grow">
                          {authState.profile?.email}
                        </span>
                      </dd>
                    </div>
                    {authState.profile?.userType === 'data_partner' && (
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          IP Addresses
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow space-y-2">
                            <div>
                              {(credentials?.sourceIPs || []).join(', ')}
                            </div>
                            <div>
                              <button
                                className="btn btn-white"
                                onClick={() => {
                                  changeSourceIPsMut.mutate('current');
                                }}
                              >
                                Enable transfer from this computer
                              </button>
                            </div>
                          </span>
                          <span
                            className="ml-4 flex-shrink-0"
                            onClick={() => {
                              sourceIPEditModalOpenChange(true);
                              sourceIPReset({
                                sourceIPs: (credentials?.sourceIPs || []).join(
                                  ', '
                                ),
                              });
                            }}
                          >
                            <button
                              type="button"
                              className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              Edit
                            </button>
                          </span>
                        </dd>
                      </div>
                    )}

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        S3 Credentials
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span className="flex-grow">{renderCredentials()}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={sourceIPEditModalOpen}
          onRequestClose={() => sourceIPEditModalOpenChange(false)}
          className="w-96"
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              onSourceIPSubmit();
            }}
          >
            <div className="text-lg mb-3">Edit IP Addresses</div>
            <div className="mb-3">
              <label htmlFor="name" className="input-label">
                IP Addresses
              </label>
              <input
                name="sourceIPs"
                type="text"
                placeholder="Example: 1.1.1.1, 1.1.1.2"
                className={cx('text-input w-full mt-2', {
                  'text-input-error': !!sourceIPErrors.sourceIPs,
                })}
                ref={sourceIPRegister({required: true})}
                autoComplete="off"
              />
              <p className="mt-1 text-xs text-gray-400">
                List of IP addresses to whitelist, separated by commas
              </p>
            </div>
            {sourceIPErrors.sourceIPs && (
              <div className="mt-2 text-sm text-red-600">
                {sourceIPErrors.sourceIPs?.type === 'required' && (
                  <span>At least 1 IP Address is required</span>
                )}
              </div>
            )}
            <div className="flex flex-row-reverse">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={changeSourceIPsMut.isLoading}
              >
                Save
              </button>
            </div>
          </form>
        </Modal>
      </ApplicationShell>
    </>
  );
};
