import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {trackEvent} from '../../utils/tracking';
import {SavedSearches} from '../../models/search';
import {Card} from '../../core/layout/card';
import ReactTooltip from 'react-tooltip';
import {HiHeart} from 'react-icons/hi';
import {removeAggsField} from '../../models/search';

// Function to extract filters from the Elasticsearch query
function extractFilters(jsonObj: any) {
  try {
    const filters = [];
    // Check if the 'filter' object exists
    if (
      jsonObj.aggs &&
      jsonObj.aggs.facet_bucket_all &&
      jsonObj.aggs.facet_bucket_all.filter &&
      jsonObj.aggs.facet_bucket_all.filter.bool &&
      Array.isArray(jsonObj.aggs.facet_bucket_all.filter.bool.must)
    ) {
      for (const mustClause of jsonObj.aggs.facet_bucket_all.filter.bool.must) {
        if (mustClause.bool && mustClause.bool.should) {
          for (const shouldClause of mustClause.bool.should) {
            if (shouldClause.term) {
              // Extract filter name and value
              let filterName = Object.keys(shouldClause.term)[0];
              // eslint-disable-next-line
              const filterValue = shouldClause.term[filterName];
              filterName = filterName.replace('.keyword', '');
              filters.push(`${filterName}: ${filterValue}`);
            }
          }
        }
      }
    }
    // age filter with query
    // "query": {"bool": {"must": [{"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 20, "lte": 50}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      Array.isArray(jsonObj.query.bool.must) &&
      jsonObj.query.bool.must[0].bool &&
      jsonObj.query.bool.must[0].bool.filter &&
      jsonObj.query.bool.must[0].bool.filter[0].bool &&
      jsonObj.query.bool.must[0].bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.must[0].bool.filter[0].bool
        .filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }
    // age filter without query
    // "query": {"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 22, "lte": 24}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      jsonObj.query.bool.filter &&
      jsonObj.query.bool.filter[0].bool &&
      jsonObj.query.bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.filter[0].bool.filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }

    return filters.join(', ');
  } catch (error) {
    console.error('Error parsing Elasticsearch query', error);
    return '';
  }
}

export const processElasticSearchBody = (
  elasticSearchBody: any
): [Set<string>, string, string] => {
  const uniqueQueries = new Set<string>();
  const crossFieldsQueries = new Set<string>();
  let filterList = '';
  let operator = ', ';
  let isSynonym = false;
  const elasticSearchBodyObj = removeAggsField(elasticSearchBody) as any;
  const addQuery = (query: string | undefined, type: string | undefined) => {
    if (query) {
      if (type === 'best_fields') {
        crossFieldsQueries.add(query);
      } else {
        uniqueQueries.add(query);
      }
    }
  };
  try {
    filterList = extractFilters(elasticSearchBody);
    if (Array.isArray(elasticSearchBodyObj.query?.bool?.must)) {
      elasticSearchBodyObj.query.bool.must.forEach((mustItem: any) => {
        if (mustItem.bool?.should) {
          mustItem.bool.should.forEach((shouldItem: any) => {
            if (shouldItem.multi_match) {
              addQuery(
                shouldItem.multi_match.query,
                shouldItem.multi_match.type
              );
            }
          });
        }
      });
    }

    operator = ' AND ';
  } catch (error) {
    const shouldObj =
      elasticSearchBodyObj?.query?.bool?.must?.[0]?.bool?.should;
    let exactMatch =
      elasticSearchBodyObj?.query?.bool?.must?.[0]?.bool?.should?.match_phrase
        ?.report;
    if (Array.isArray(shouldObj)) {
      // case with | operator used
      operator = ' | ';
      shouldObj.forEach((shouldItem: any) => {
        if (shouldItem.bool?.should?.[0]?.multi_match?.query) {
          uniqueQueries.add(shouldItem.bool.should[0].multi_match.query);
        } else if (shouldItem.match_phrase?.report?.query) {
          isSynonym = true;
          uniqueQueries.add(`"${shouldItem.match_phrase.report.query}"`);
        }
      });
    } else if (exactMatch) {
      if (typeof exactMatch === 'object') {
        exactMatch = exactMatch.query;
      }
      // case with quotes used
      if (typeof exactMatch === 'string') {
        uniqueQueries.add('"' + exactMatch + '"');
      }
    }
    const queryValue =
      elasticSearchBodyObj?.query?.bool?.must?.[0]?.bool?.should?.[0]
        ?.multi_match?.query;
    if (queryValue) {
      uniqueQueries.add(queryValue);
    }
  }
  // if isSynonym then join uniqueQueries with | operator
  if (isSynonym) {
    operator = ' | ';
    const synonymQueries = Array.from(uniqueQueries).join(operator);
    uniqueQueries.clear();
    uniqueQueries.add(synonymQueries);
  }

  return [uniqueQueries, filterList, operator];
};

export const SaveSearchCard = ({
  savedSearch,
  action,
}: {
  savedSearch: SavedSearches;
  action?: (actionType: 'edit' | 'delete') => void;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [savedSearch]);

  const [newUniqueQueries, filterList, operator] = processElasticSearchBody(
    savedSearch.elasticSearchBody
  );

  const queryContent = Array.from(newUniqueQueries).join(operator);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US').replace(',', '');
  };

  const urlObject = new URL(savedSearch.url);
  const relativeUrl =
    urlObject.pathname + urlObject.search + '&autosubmit=true';

  const handleOpenSearch = () => {
    trackEvent('CLICK_SAVED_SEARCH_CARD');
    navigate(relativeUrl, {
      state: {elasticSearchBody: savedSearch.elasticSearchBody},
    });
  };

  return (
    <>
      <Card data-cy="datasetCard">
        <div className="text-gray-400 flex justify-between items-center mb-6 pb-6 border-b">
          <div>
            <div className="flex text-gray-900 items-center">
              <div>Keyword: {queryContent}</div>
            </div>
            {filterList && (
              <div className="mt-4">
                <strong>Filters:</strong> {filterList}
              </div>
            )}
          </div>
          <div>
            {formatDate(savedSearch.createdAt)}
            <div className="inline ml-4">
              <button
                className="btn btn-link inline-block px-0 py-0"
                data-tip="Delete saved searches"
                data-event="mouseenter"
                data-event-off="mouseleave click"
                onClick={() => {
                  trackEvent('CLICK_DELETE_SAVED_SEARCHES_BTN', {
                    ssId: savedSearch.ssId,
                  });
                  action && action('delete');
                }}
              >
                <HiHeart className="w-5 h-5 inline" />
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div>
              <div
                className="text-2xl font-extrabold"
                data-cy="datasetStudyCount"
              >
                {savedSearch.numberOfResultsSaved.toLocaleString('en-US')}
              </div>
              <div className="text-gray-500">Total studies results</div>
            </div>
          </div>
          <div>
            <button
              onClick={handleOpenSearch}
              className="btn btn-primary max-w-[40%] float-right"
            >
              View Search results
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};
