import _ from 'lodash';
import {DateTime} from 'luxon';
import {AxiosInstance, AxiosResponse} from 'axios';

import {TagResponse, Tag, responseToTag} from './tags';

export interface Report {
  rid: number;
  studyId: string;
  patientId: string;
  reportTitle: string;
  modality: string;
  bodyPart: string;
  text?: string;
  manufacturer?: string;
  examDate?: DateTime;
  assignedDatasets?: number[];
  tags?: Tag[];
  vendor?: string;
  gender?: string;
  ethnicity?: string;
  race?: string;
  slice_thickness?: number;
  patient_age?: number;
}

export interface ReportResponse {
  rid: number;
  study_id: string;
  patient_id: string;
  report_title: string;
  modality: string;
  body_part: string;
  report: string;
  manufacturer: string;
  exam_date: string;
  assigned_datasets?: number[];
  tags?: TagResponse[];
  vendor?: string;
  gender?: string;
  ethnicity?: string;
  race?: string;
  slice_thickness?: number;
  age?: number;
}

export const responseToReport = (reportResp: ReportResponse) => {
  const report: Report = {
    rid: reportResp.rid,
    studyId: reportResp.study_id,
    patientId: reportResp.patient_id,
    reportTitle: reportResp.report_title,
    modality: reportResp.modality,
    bodyPart: reportResp.body_part,
    text: reportResp.report,
    examDate:
      !_.isEmpty(reportResp.exam_date) &&
      reportResp.exam_date !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(reportResp.exam_date)
        : undefined,
    manufacturer: reportResp.manufacturer,
    assignedDatasets: reportResp.assigned_datasets,
    tags: reportResp.tags
      ?.map(tag => responseToTag(tag))
      .sort((firstTag, secondTag) => (firstTag.name > secondTag.name ? 1 : -1)),
    vendor: reportResp.vendor,
    gender: reportResp.gender,
    ethnicity: reportResp.ethnicity,
    race: reportResp.race,
    slice_thickness:
      reportResp.slice_thickness && reportResp.slice_thickness > 0
        ? reportResp.slice_thickness
        : undefined,
    patient_age:
      reportResp.age && reportResp.age > 0 ? reportResp.age : undefined,
  };

  return report;
};

export const fetchReport = (http: AxiosInstance, studyId: string) => {
  return http
    .get(`/v1/report/${studyId}`)
    .then((response: AxiosResponse<ReportResponse>) => {
      const {data} = response;

      const report = responseToReport(data);

      return report;
    });
};

export const fetchPatientReports = (http: AxiosInstance, patientId: string) => {
  return http
    .get(`/v1/patients/${patientId}/reports`)
    .then((response: AxiosResponse<ReportResponse[]>) => {
      const {data} = response;

      const reports = data.map(r => responseToReport(r));

      return reports;
    });
};

export const reportPHI = (http: AxiosInstance, studyId: string) => {
  return http.put(`/v1/report/${studyId}/phi`);
};

export interface ClassificationElement {
  code: string;
  label: string;
}
export interface ClassificationData {
  modalities: ClassificationElement[];
  body_parts: ClassificationElement[];
}

export const fetchModalitiesAndBodyparts = (http: AxiosInstance) => {
  return http
    .get('/v1/dict/classification')
    .then((response: AxiosResponse<ClassificationData>) => {
      const {modalities, body_parts} = response.data;
      return {modalities, body_parts};
    });
};

export const updateClassification = (
  http: AxiosInstance,
  studyId: string,
  classificationType: string,
  classificationLabel: string
) => {
  return http.post('/v1/fix/classification', {
    study_id: studyId,
    type: classificationType,
    value: classificationLabel,
  });
};
