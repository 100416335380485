import React from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {MenuDropdownSingle} from '../../core/components/menu';
import {ToastMessage} from '../../core/components/toast';
import {
  ClassificationElement,
  fetchModalitiesAndBodyparts,
  updateClassification,
} from '../../models/report';
import {useAxios} from 'src/utils/http';

export const ClassificationDropdown = ({
  studyId,
  modality,
  bodyPart,
  disabled = false,
}: {
  studyId: string;
  modality: string;
  bodyPart: string;
  disabled?: boolean;
}) => {
  const http = useAxios();
  const {data: classification} = useQuery(
    ['internalGetClassificationData'],
    () => {
      return fetchModalitiesAndBodyparts(http);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const queryClient = useQueryClient();
  const handleUpdateSuccess = () => {
    toast(<ToastMessage title={'Update Successful'} icon="success" />);
    queryClient.invalidateQueries(['report']);
  };

  const handleUpdateError = (err: any) => {
    const message =
      err?.response?.data?.message ?? 'Error updating classification';
    toast(<ToastMessage title={message} icon="error" />);
  };

  const handleModalitySelect = (modality: ClassificationElement) => {
    updateClassification(http, studyId, 'modality', modality.code)
      .then(handleUpdateSuccess)
      .catch(handleUpdateError);
  };

  const handleBodyPartSelect = (bodyPart: ClassificationElement) => {
    updateClassification(http, studyId, 'body_part', bodyPart.code)
      .then(handleUpdateSuccess)
      .catch(handleUpdateError);
  };

  return (
    <>
      <hr className="border border-gray-200 my-5" />
      <div>
        <div className="text-gray-400 text-xs font-medium">
          Current Modality: {modality}
        </div>
      </div>
      <div>
        <div className="text-gray-400 text-xs font-medium">
          Current Body Part: {bodyPart}
        </div>
      </div>
      <hr className="border border-gray-200 my-5" />
      <div>
        <MenuDropdownSingle
          buttonText="Fix the Modality"
          menuItems={[
            ...(classification?.modalities.length
              ? classification.modalities
                  .filter(ccc => {
                    return ccc.code !== modality;
                  })
                  .map(ccc => {
                    return {
                      itemText: () => <h1>{ccc.label}</h1>,
                      disabled: disabled,
                      onClick: () => handleModalitySelect(ccc),
                    };
                  })
              : [
                  {
                    itemText: () => 'No available modalities',
                    disabled: true,
                  },
                ]),
          ]}
        />
      </div>
      <hr className="border border-gray-200 my-5" />
      <div>
        <MenuDropdownSingle
          buttonText="Fix the Body Part"
          menuItems={[
            ...(classification?.body_parts.length
              ? classification.body_parts
                  .filter(ccc => {
                    return ccc.code !== bodyPart;
                  })
                  .map(ccc => {
                    return {
                      itemText: () => <h1>{ccc.label}</h1>,
                      disabled: disabled,
                      onClick: () => handleBodyPartSelect(ccc),
                    };
                  })
              : [
                  {
                    itemText: () => 'No available body parts',
                    disabled: true,
                  },
                ]),
          ]}
        />
      </div>
    </>
  );
};
