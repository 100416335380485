/* eslint-disable security/detect-object-injection */
import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {
  HiOutlineXCircle,
  HiInformationCircle,
  HiOutlineExclamationCircle,
  HiVideoCamera,
} from 'react-icons/hi';
import _ from 'lodash';
import cx from 'classnames';
import {useQuery} from 'react-query';

import {encodeURLObject} from '../../utils/router-helper';
import {trackEvent} from '../../utils/tracking';
import {Dropdown} from '../../core/components/dropdown';
import {Select, SelectOption} from '../../core/components/select';
import {
  Keyword,
  SearchQuery,
  fetchModalities,
  fetchBodyParts,
  fetchSourceLocations,
  fetchAllowedModalities,
  fetchAllowedDataPartners,
  SourceLocationStatus,
} from '../../models/search';
import {SearchTour} from '../../core/components/tour/search-tour';
import {renderCompactKeywords} from './keyword';
import {useAuth} from '../../hooks/auth';
import {useAxios} from 'src/utils/http';

export const emptyKeyword: Keyword = {
  area: 'all',
  operator: 'contains substring',
  text: '',
  booloperator: 'AND',
};

export const emptySearchQuery: SearchQuery = {
  modality: [],
  bodyArea: [],
  sourceLocation: ['us_east'],
  keywords: [_.cloneDeep(emptyKeyword), _.cloneDeep(emptyKeyword)],
  patients: '',
  additionalPatientStudies: false,
  isPatientSearch: false,
  vendor: [],
  gender: [],
  ethnicity: [],
  race: [],
  sliceThickness: [],
  age: [],
};

export const emptyPatientSearchQuery: SearchQuery = {
  ...emptySearchQuery,
  isPatientSearch: true,
};

export const keywordScopeOptions: SelectOption<string>[] = [
  {value: 'all', label: 'Report'},
  {value: 'impression', label: 'Impression'},
];
export const keywordOperatorOptions: SelectOption<string>[] = [
  {value: 'contains substring', label: 'Contains'},
  {value: 'excludes substring', label: 'Excludes'},
  {
    value: 'contains substring (no negations)',
    label: 'Contains (No Negations)',
  },
];
export const keywordBoolOperatorOptions: SelectOption<string>[] = [
  {value: 'AND', label: 'And'},
  {value: 'OR', label: 'Or'},
];

const maxKeywords = 8;

export const SearchForm = ({
  onSearch,
  query,
  queryChange,
  searchDisabled = false,
  compactable = false,
  initialCompact = false,
  tourActive = false,
  patientFilter = false,
  isPatientSearch = false,
  isAdvanced = false,
}: {
  onSearch?: (query: SearchQuery) => boolean;
  query: SearchQuery;
  queryChange: (query: SearchQuery) => unknown;
  searchDisabled?: boolean;
  compactable?: boolean;
  initialCompact?: boolean;
  tourActive?: boolean;
  patientFilter?: boolean;
  isPatientSearch?: boolean;
  isAdvanced?: boolean;
}) => {
  const http = useAxios();
  const searchQuery = _.cloneDeep(query);
  const navigate = useNavigate();
  const {authState} = useAuth();
  const [searchTourActive, searchTourActiveChange] = useState(false);

  useEffect(() => {
    searchTourActiveChange(tourActive);
  }, [tourActive]);

  const [compact, compactChange] = useState(initialCompact);

  const {data: modalities} = useQuery(
    ['modalities'],
    () => fetchModalities(http),
    {
      staleTime: Infinity,
    }
  );
  const {data: bodyParts} = useQuery(
    ['bodyParts'],
    () => fetchBodyParts(http),
    {
      staleTime: Infinity,
    }
  );
  const {data: sourceLocations} = useQuery(
    ['sourceLocations'],
    () => fetchSourceLocations(http),
    {
      staleTime: Infinity,
    }
  );
  const {data: allowedModalities} = useQuery(
    ['allowedModalities'],
    () => fetchAllowedModalities(http),
    {
      staleTime: Infinity,
    }
  );

  const {data: allowedDataPartners} = useQuery(
    ['allowedBodyParts'],
    () => fetchAllowedDataPartners(http),
    {
      staleTime: Infinity,
    }
  );

  // Hardcoding in values for Change Healthcare Demo
  const vendorOptions: SelectOption<string>[] = [
    {
      label: 'Siemens',
      value: 'SIEMENS',
    },
    {
      label: 'Philips',
      value: 'PHILIPS',
    },
    {
      label: 'GE',
      value: 'GE',
    },
  ];
  const genderOptions: SelectOption<string>[] = [
    {label: 'Male', value: 'M'},
    {label: 'Female', value: 'F'},
  ];
  const ethnicityOptions: SelectOption<string>[] = [
    {label: 'Hispanic or Latino', value: 'HISPANIC'},
    {label: 'Other', value: 'OTHER'},
  ];
  const raceOptions: SelectOption<string>[] = [
    {label: 'American Indian or Alaska Native', value: 'AMERICAN_INDIAN'},
    {label: 'Asian', value: 'ASIAN'},
    {label: 'Black or African American', value: 'BLACK'},
    {
      label: 'Native Hawaiian or Other Pacific Islander',
      value: 'NATIVE_HAWAIIAN',
    },
    {label: 'White', value: 'WHITE'},
    {label: 'Other', value: 'OTHER'},
  ];

  const modalityOptions = _.chain([...(modalities ?? [])])
    .map(modality => {
      const disabled =
        allowedModalities && !_.includes(allowedModalities, modality.modality);
      return {
        value: modality.modality,
        label: (disabled ? 'Coming Soon: ' : '') + modality.label,
        disabled: disabled,
      };
    })
    .filter(
      modality =>
        !(
          (modality.value === '' || modality.value === 'Others') &&
          modality.disabled
        )
    )
    .orderBy(modality => modality.disabled)
    .value();

  const modalityOptionsDividerIndex = modalityOptions.findIndex(
    modality => modality.disabled
  );

  const sourceLocationOptions = _.chain(sourceLocations)
    .map(sourceLocation => {
      const disabled =
        allowedDataPartners &&
        !_.includes(allowedDataPartners, sourceLocation.datapartner);

      let label = (disabled ? 'Coming Soon: ' : '') + sourceLocation.label;
      if (
        authState.profile?.email.split('@').pop() === 'radsource.us' &&
        sourceLocation.label === 'US Telerad 1'
      ) {
        label = sourceLocation.label + ' (pending)';
      }

      return {
        value: sourceLocation.datapartner,
        label: label,
        status: sourceLocation.status,
        disabled: disabled,
      };
    })
    .orderBy(sourceLocation => sourceLocation.disabled)
    .filter(
      sourceLocation =>
        !(sourceLocation.value === 'others' && sourceLocation.disabled)
    )
    .value();

  const sourceLocationDividerIndex = sourceLocationOptions.findIndex(
    location => location.disabled
  );

  const bodyPartOptions = _.map(bodyParts?.sections, section => {
    return {
      section: section.section,
      label: section.label,
      categories: _.filter(bodyParts?.categories, {section: section.section}),
    };
  });

  const bodyPartColumns = [
    ['HEADNECK', 'SPINE', 'CARDIOVASCULAR'],
    ['MUSCULOSKELETAL'],
    ['BODY', 'OTHER'],
  ];

  const queryChanged = (q: SearchQuery) => {
    queryChange(_.cloneDeep(q));
  };

  // Sync query
  const removeKeyword = (index: number) => {
    searchQuery.keywords = _.remove(
      searchQuery.keywords,
      (_, i) => i !== index
    );

    queryChanged(searchQuery);
  };

  const changeMultiselect = (
    field:
      | 'modality'
      | 'sourceLocation'
      | 'bodyArea'
      | 'vendor'
      | 'gender'
      | 'ethnicity'
      | 'race',
    selectedOption: string,
    add: boolean
  ) => {
    if (add) {
      // Add to selection
      searchQuery[field] = _.uniq([...searchQuery[field], selectedOption]);
    } else {
      // Remove from selection
      searchQuery[field] = _.filter(
        searchQuery[field],
        v => v !== selectedOption
      );
    }

    queryChanged(searchQuery);
  };

  const renderModalityDropdown = () => {
    return (
      <Dropdown
        text={searchQuery.modality
          .map(v => _.find(modalityOptions, {value: v})?.label || '')
          .join(', ')}
        contained
      >
        <div className="grid grid-cols-1 w-full auto-rows-min py-1">
          {modalityOptions?.map((v, i) => (
            <Fragment key={'fragment-' + i}>
              {i === modalityOptionsDividerIndex && (
                <div className="py-1">
                  <hr className="divide-y divide-gray-400"></hr>
                </div>
              )}
              <div className="flex">
                <label
                  className={cx('relative flex flex-grow items-start py-2', {
                    'select-none': v.disabled,
                    'px-4 hover:bg-gray-100 hover:text-gray-900 text-gray-700':
                      !v.disabled,
                  })}
                  key={'modality-' + i}
                >
                  {!v.disabled && (
                    <div
                      className="flex items-center h-5"
                      data-cy="SearchForm_modalityDiv"
                    >
                      <input
                        id={'modality-' + v.value}
                        name={'modality-' + v.value}
                        type="checkbox"
                        className="checkbox-input"
                        checked={_.includes(searchQuery.modality, v.value)}
                        onChange={e =>
                          changeMultiselect(
                            'modality',
                            v.value,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  )}
                  <div className="ml-3 text-sm inline-block w-full">
                    <div
                      className={cx('font-normal inline-block w-full', {
                        'text-gray-400': v.disabled,
                      })}
                    >
                      {v.label}
                    </div>
                  </div>
                </label>
              </div>
            </Fragment>
          ))}
        </div>
      </Dropdown>
    );
  };

  const renderBodyAreaDropdown = () => {
    return (
      <Dropdown
        text={searchQuery.bodyArea
          .map(v => _.find(bodyParts?.categories, {bodyPart: v})?.label || '')
          .join(', ')}
      >
        <div className="grid grid-cols-3 w-full auto-rows-min pt-3 pb-1.5">
          {bodyPartColumns.map((bodyPartColumn, columnIndex) => (
            <div
              className="grid grid-cols-1 w-full gap-y-1.5 auto-rows-min"
              key={columnIndex}
            >
              {bodyPartColumn.map((section, sectionIndex) => {
                const bodyPartSection = _.find(bodyPartOptions, {section});
                return (
                  bodyPartSection && (
                    <div
                      className="grid grid-cols-1 w-full auto-rows-min"
                      key={sectionIndex}
                    >
                      <span
                        className={cx(
                          'text-gray-400 uppercase font-medium text-sm tracking-wider pb-1.5',
                          {
                            'pl-4': columnIndex === 0,
                            'pl-2': columnIndex > 0,
                            'pr-2': columnIndex < bodyPartColumns.length - 1,
                            'pr-4': columnIndex === bodyPartColumns.length - 1,
                          }
                        )}
                      >
                        {bodyPartSection.label}
                      </span>
                      {bodyPartSection.categories.map(
                        (category, categoryIndex) => {
                          const option: SelectOption<string> = {
                            value: category.bodyPart,
                            label: category.label,
                          };
                          return (
                            <label
                              className={cx(
                                'relative flex items-start hover:bg-gray-100 hover:text-gray-900 text-gray-700 py-1.5',
                                {
                                  'pl-4': columnIndex === 0,
                                  'pl-2': columnIndex > 0,
                                  'pr-2':
                                    columnIndex < bodyPartColumns.length - 1,
                                  'pr-4':
                                    columnIndex === bodyPartColumns.length - 1,
                                }
                              )}
                              key={categoryIndex}
                              data-cy="SearchForm_bodyPartLabel"
                            >
                              <div className="flex items-center h-5">
                                <input
                                  id={'bodyArea-' + option.value}
                                  name={'bodyArea-' + option.value}
                                  type="checkbox"
                                  className="checkbox-input"
                                  checked={_.includes(
                                    searchQuery.bodyArea,
                                    option.value
                                  )}
                                  onChange={e =>
                                    changeMultiselect(
                                      'bodyArea',
                                      (option as SelectOption<string>).value,
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm inline-block w-full">
                                <div className="font-normal text-sm inline-block w-full">
                                  {option.label}
                                </div>
                              </div>
                            </label>
                          );
                        }
                      )}
                    </div>
                  )
                );
              })}
            </div>
          ))}
        </div>
      </Dropdown>
    );
  };

  const renderSourceLocationDropdown = () => {
    return (
      <Dropdown
        text={searchQuery.sourceLocation
          .map(v => _.find(sourceLocationOptions, {value: v})?.label || '')
          .join(', ')}
        contained
      >
        <div className="grid grid-cols-1 w-full auto-rows-min py-1">
          {sourceLocationOptions?.map((v, i) => (
            <Fragment key={'fragment-' + i}>
              {i === sourceLocationDividerIndex && (
                <div className="py-1">
                  <hr className="divide-y divide-gray-400"></hr>
                </div>
              )}
              <div className="flex">
                <label
                  className={cx('relative flex flex-grow items-start py-2', {
                    'select-none': v.disabled,
                    'px-4 hover:bg-gray-100 hover:text-gray-900 text-gray-700':
                      !v.disabled,
                  })}
                  key={'sourceLocation-' + i}
                >
                  {!v.disabled && (
                    <div
                      className="flex items-center h-5"
                      data-cy="SearchForm_sourceLocationDiv"
                    >
                      <input
                        id={'sourceLocation-' + v.value}
                        name={'sourceLocation-' + v.value}
                        type="checkbox"
                        className="checkbox-input"
                        checked={_.includes(
                          searchQuery.sourceLocation,
                          v.value
                        )}
                        onChange={e =>
                          changeMultiselect(
                            'sourceLocation',
                            v.value,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  )}
                  <div className="ml-3 text-sm inline-block w-full">
                    <div
                      className={cx('font-normal inline-block w-full', {
                        'text-gray-400': v.disabled,
                      })}
                    >
                      {v.label}
                    </div>
                  </div>
                </label>
                {v.status === SourceLocationStatus.DOWN && (
                  <div className="has-tooltip mr-3 inline-block justify-self-end self-center">
                    <HiOutlineExclamationCircle className="mr-1 text-red-500 text-lg hover:cursor-pointer" />
                    <span className="tooltip rounded shadow p-1 bg-gray-50 text-red-500 font-light -ml-32 -mt-2">
                      {'Potential Delay'}
                    </span>
                  </div>
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </Dropdown>
    );
  };

  // render generic dropdown for advanced search options
  const renderGenericSearchDropdown = ({
    options,
    queryField,
  }: {
    options: SelectOption<string>[];
    queryField: 'vendor' | 'gender' | 'ethnicity' | 'race';
  }) => {
    return (
      <Dropdown
        text={searchQuery[queryField]
          .map(v => _.find(options, {value: v})?.label || '')
          .join(', ')}
        contained
      >
        <div className="grid grid-cols-1 w-full auto-rows-min py-1">
          {options?.map((v, i) => (
            <Fragment key={'fragment-' + i}>
              <div className="flex">
                <label
                  className={cx('relative flex flex-grow items-start py-2', {
                    'select-none': v.disabled,
                    'px-4 hover:bg-gray-100 hover:text-gray-900 text-gray-700':
                      !v.disabled,
                  })}
                  key={`${queryField}-${i}`}
                >
                  {!v.disabled && (
                    <div className="flex items-center h-5">
                      <input
                        id={`${queryField}-${v.value}`}
                        name={`${queryField}-${v.value}`}
                        type="checkbox"
                        className="checkbox-input"
                        checked={_.includes(searchQuery[queryField], v.value)}
                        onChange={e =>
                          changeMultiselect(
                            queryField,
                            v.value,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  )}
                  <div className="ml-3 text-sm inline-block w-full">
                    <div
                      className={cx('font-normal inline-block w-full', {
                        'text-gray-400': v.disabled,
                      })}
                    >
                      {v.label}
                    </div>
                  </div>
                </label>
              </div>
            </Fragment>
          ))}
        </div>
      </Dropdown>
    );
  };

  const changeMinMax = (
    field: 'sliceThickness' | 'age',
    value: string,
    index: number
  ) => {
    searchQuery[field][index] =
      field === 'age' ? parseInt(value) : parseFloat(value);
    queryChanged(searchQuery);
  };

  const renderSliceThickness = () => {
    return (
      <div className="flex flex-col gap-y-2">
        <div className="grid grid-cols-2 w-full gap-x-4">
          <input
            type="number"
            min="0"
            max="100"
            step=".1"
            className="text-input w-full text-gray-700"
            value={searchQuery.sliceThickness[0] ?? ''}
            onChange={e => changeMinMax('sliceThickness', e.target.value, 0)}
            placeholder={'Min'}
          />
          <input
            type="number"
            min="0"
            max="100"
            step=".1"
            className="text-input w-full text-gray-700"
            value={searchQuery.sliceThickness[1] ?? ''}
            onChange={e => changeMinMax('sliceThickness', e.target.value, 1)}
            placeholder={'Max'}
          />
        </div>
      </div>
    );
  };

  const renderAge = () => {
    return (
      <div className="flex flex-col gap-y-2">
        <div className="grid grid-cols-2 w-full gap-x-4">
          <input
            type="number"
            min="0"
            max="130"
            step="1"
            className="text-input w-full text-gray-700"
            value={searchQuery.age[0] ?? ''}
            onChange={e => changeMinMax('age', e.target.value, 0)}
            placeholder={'Min'}
          />
          <input
            type="number"
            min="0"
            max="130"
            step="1"
            className="text-input w-full text-gray-700"
            value={searchQuery.age[1] ?? ''}
            onChange={e => changeMinMax('age', e.target.value, 1)}
            placeholder={'Max'}
          />
        </div>
      </div>
    );
  };

  const changeKeyword = (
    field: 'area' | 'operator' | 'text' | 'booloperator',
    value: string,
    index: number
  ) => {
    searchQuery.keywords[index][field] = value;

    queryChanged(searchQuery);
  };

  const addKeyword = () => {
    searchQuery.keywords = _.concat(searchQuery.keywords, emptyKeyword);

    queryChanged(searchQuery);
  };

  const renderKeywords = () => {
    return (
      <div className="flex flex-col gap-y-2">
        {searchQuery.keywords.map((keyword, keywordIndex) => (
          <div
            className="grid grid-cols-12 w-full gap-x-4"
            key={'keyword-' + keywordIndex}
          >
            <div
              className="col-span-2"
              data-tour="search-form-step-report-impression"
            >
              <Select
                text={
                  _.find(keywordScopeOptions, {value: keyword.area})?.label ||
                  ''
                }
                value={keyword.area}
                options={keywordScopeOptions}
                onSelect={(option: SelectOption<string>) =>
                  changeKeyword('area', option.value, keywordIndex)
                }
                contained
              />
            </div>
            <div className="col-span-2" data-tour="search-form-step-contains">
              <Select
                text={
                  _.find(keywordOperatorOptions, {value: keyword.operator})
                    ?.label || ''
                }
                value={keyword.operator}
                options={keywordOperatorOptions}
                onSelect={(option: SelectOption<string>) =>
                  changeKeyword('operator', option.value, keywordIndex)
                }
                contained
              />
            </div>
            <div className="col-span-6">
              <input
                type="text"
                className="text-input w-full text-gray-700"
                value={keyword.text}
                onChange={e =>
                  changeKeyword('text', e.target.value, keywordIndex)
                }
                onKeyPress={e =>
                  e.key === 'Enter' && !searchDisabled && search()
                }
                placeholder={
                  keywordIndex === 0
                    ? 'Ex. To search "mass or nodule," type: mass|nodule'
                    : ''
                }
                data-cy="SearchForm_keywordInput"
                data-tour="search-form-step-terms"
              />
            </div>
            <div className="col-span-1 w-full flex items-center justify-center">
              {keywordIndex === searchQuery.keywords.length - 1 ? (
                <button
                  data-tour="search-form-step-add"
                  className={cx('btn-link font-medium text-sm', {
                    hidden: searchQuery.keywords.length >= maxKeywords,
                  })}
                  onClick={() => {
                    trackEvent('CLICK_ADD_KEYWORD_BTN');
                    addKeyword();
                  }}
                >
                  Add term
                </button>
              ) : (
                <div className="text-gray-500 text-sm">AND</div>
              )}
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <button
                onClick={() => {
                  trackEvent('CLICK_REMOVE_KEYWORD_BTN');
                  removeKeyword(keywordIndex);
                }}
                className={searchQuery.keywords.length <= 1 ? 'hidden' : ''}
              >
                <HiOutlineXCircle className="h-6 w-6 text-red-500" />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const search = () => {
    trackEvent('CLICK_SEARCH_BTN', {searchQuery});

    let redirect = true;
    if (onSearch) {
      redirect = onSearch(searchQuery);
    }
    if (redirect) {
      navigate({
        pathname: '/search/results',
        search: queryString.stringify({q: encodeURLObject(searchQuery)}),
      });
    }

    if (compactable) {
      compactChange(true);
    }
  };

  const renderPatientFilter = () => {
    return (
      <>
        <label className="block font-medium mb-3">Patient</label>
        <input
          type="text"
          className="text-input w-full text-gray-700"
          value={searchQuery.patients}
          placeholder="List of patient IDs (comma separated)"
          onChange={e => {
            searchQuery.patients = e.target.value;
            queryChanged(searchQuery);
          }}
          onBlur={e => {
            const patients = _.chain(e.target.value)
              .split(',')
              .map(patient => _.trim(patient))
              .filter(patient => !_.isEmpty(patient))
              .join(', ')
              .value();
            if (patients !== searchQuery.patients) {
              searchQuery.patients = patients;
              queryChanged(searchQuery);
            }
          }}
        />
      </>
    );
  };

  const isCompact = compactable && compact;

  return (
    <>
      {!isCompact && (
        <div>
          <div className="mb-6">
            <h1
              className="text-xl mb-2 font-medium flex items-center"
              data-tour="search-form-step-start-search"
            >
              Text Search
              <HiInformationCircle
                className="mx-2 text-primary hover:text-primary-active hover:cursor-pointer"
                title="How to Search Tour"
                onClick={() => {
                  trackEvent('CLICK_SEARCH_TOUR_BTN');
                  searchTourActiveChange(true);
                }}
              />
              <a
                href={
                  'https://www.loom.com/share/361e66e1f9a94bd99a4725f9812c5ea7'
                }
                target="_blank"
                title="Demo Search Video"
                rel="noreferrer"
                onClick={() =>
                  trackEvent('CLICK_DEMO_HOW_TO_SEARCH_BTN', {
                    url: 'https://www.loom.com/share/361e66e1f9a94bd99a4725f9812c5ea7',
                  })
                }
              >
                <HiVideoCamera className="text-primary hover:text-primary-active hover:cursor-pointer" />
              </a>
            </h1>

            <p className="text-sm text-help font-medium">
              Search through full radiology reports, impressions, or other exam
              text.
              <br />
              <span className="italic font-normal">* required fields</span>
            </p>
          </div>

          <div className="grid grid-cols-3 w-full mb-6 gap-4 text-gray-700">
            <div className="col-span-1" data-tour="search-form-step-modality">
              <label className="block font-medium mb-3">
                Modality <span className="text-red-600">*</span>
              </label>
              <div className="relative" data-cy="SearchForm_modalityDropdown">
                {renderModalityDropdown()}
              </div>
            </div>
            <div
              className="col-span-1"
              data-tour="search-form-step-bodyArea"
              data-cy="SearchForm_bodyAreaDropdown"
            >
              <label className="block font-medium mb-3">Body Area</label>
              {renderBodyAreaDropdown()}
            </div>
            <div className="col-span-1" data-tour="search-form-step-location">
              <label className="block font-medium mb-3">
                Source Location <span className="text-red-600">*</span>
              </label>
              <div
                className="relative"
                data-cy="SearchForm_sourceLocationDropdown"
              >
                {renderSourceLocationDropdown()}
              </div>
            </div>
          </div>

          <div>
            <label className="block font-medium mb-3">Keywords</label>
            <div className="grid grid-cols-1 w-full gap-y-4 text-gray-700">
              <div>{renderKeywords()}</div>

              <div>{patientFilter && renderPatientFilter()}</div>

              {/* Demo Advanced Search */}
              {isAdvanced && (
                <div className="text-gray-700 font-medium">
                  <div className="mb-6">
                    <div className="text-xl mb-2">Demographics Search</div>
                    <div className="grid grid-cols-5 gap-4">
                      <div>
                        <label className="block font-medium mb-3">Age</label>
                        {renderAge()}
                      </div>
                      <div>
                        <label className="block font-medium mb-3">Gender</label>
                        {renderGenericSearchDropdown({
                          options: genderOptions,
                          queryField: 'gender',
                        })}
                      </div>
                      <div>
                        <label className="block font-medium mb-3">
                          Ethnicity
                        </label>
                        {renderGenericSearchDropdown({
                          options: ethnicityOptions,
                          queryField: 'ethnicity',
                        })}
                      </div>
                      <div>
                        <label className="block font-medium mb-3">Race</label>
                        {renderGenericSearchDropdown({
                          options: raceOptions,
                          queryField: 'race',
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="text-xl mb-2">DICOM Search</div>
                    <div className="grid grid-cols-5 gap-4">
                      <div>
                        <label className="block font-medium mb-3">Vendor</label>
                        {renderGenericSearchDropdown({
                          options: vendorOptions,
                          queryField: 'vendor',
                        })}
                      </div>
                      <div>
                        <label className="block font-medium mb-3">
                          Slice Thickness
                        </label>
                        {renderSliceThickness()}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-12 w-full gap-x-4">
                <div className="col-span-10"></div>
                <div className="col-span-2 flex flex-row-reverse gap-x-3">
                  <button
                    className="btn btn-primary px-5"
                    disabled={searchDisabled}
                    onClick={() => !searchDisabled && search()}
                    data-cy="SearchForm_searchBtn"
                  >
                    <div className="w-16">Search</div>
                  </button>
                  <button
                    className="btn btn-white px-5"
                    onClick={() =>
                      queryChanged(
                        isPatientSearch
                          ? emptyPatientSearchQuery
                          : emptySearchQuery
                      )
                    }
                    data-cy="SearchForm_clearBtn"
                  >
                    <div className="w-16">Clear</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SearchTour
            tourActive={searchTourActive}
            tourActiveChange={searchTourActiveChange}
          />
        </div>
      )}

      {isCompact && (
        <div>
          <div className="inline-block truncate max-w-full mr-4 mb-4">
            <label className="font-medium">Modality: </label>
            <div className="inline text-gray-500">
              {searchQuery.modality
                .map(v => _.find(modalityOptions, {value: v})?.label ?? '')
                .join(', ')}
            </div>
          </div>

          <div className="inline-block truncate max-w-full mr-4 mb-4">
            <label className="font-medium">Body Area: </label>
            <div className="inline text-gray-500">
              {searchQuery.bodyArea
                .map(
                  v => _.find(bodyParts?.categories, {bodyPart: v})?.label ?? ''
                )
                .join(', ') || 'All Body Areas'}
            </div>
          </div>

          <div className="inline-block truncate max-w-full mr-4 mb-4">
            <label className="font-medium">Source Location: </label>
            <div className="inline text-gray-500">
              {searchQuery.sourceLocation
                .map(
                  v => _.find(sourceLocationOptions, {value: v})?.label ?? ''
                )
                .join(', ') || 'All Locations'}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="mr-2 inline-block">
              <label className="font-medium mb-3">Keywords: </label>
              <div className="inline text-gray-500 text-sm">
                {renderCompactKeywords(searchQuery.keywords)}
              </div>
            </div>
            <div className="self-end">
              <button
                className="btn btn-secondary btn-xs whitespace-nowrap"
                onClick={() => compactChange(false)}
                data-cy="SearchForm_editSearchBtn"
              >
                Edit Search
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
