import {withSearch} from '@elastic/react-search-ui';
import {Filter, SearchContextState, helpers} from '@elastic/search-ui';
import _ from 'lodash';
import {useState} from 'react';

const {findFilterValues} = helpers;

type RangeFilterContainerProps = {
  label: string;
};

export const AgeRangeFilterContainer = (
  context: RangeFilterContainerProps & SearchContextState
) => {
  const {filters, setFilter, removeFilter} = context;

  const getCurrentAgeFilter = (filters?: Filter[]) => {
    const ageFilters = filters
      ? findFilterValues(filters, 'age_num', 'any')
      : [];

    const ageFilter: {from?: number; to?: number; name: string} = (_.find(
      ageFilters,
      {
        name: 'age_filter',
      }
    ) as {
      from?: number;
      to?: number;
      name: string;
    }) ?? {name: 'age_filter'};

    return ageFilter;
  };

  const currentAgeFilter = getCurrentAgeFilter(filters);

  const [fromValue, fromValueChange] = useState(currentAgeFilter.from);
  const [toValue, toValueChange] = useState(currentAgeFilter.to);

  const filterChanged = ({from, to}: {from?: number; to?: number}) => {
    const ageFilter = _.cloneDeep(currentAgeFilter);

    ageFilter.from = from;
    ageFilter.to = to;

    if (ageFilter.from === undefined && ageFilter.to === undefined) {
      if (!_.isEqual(currentAgeFilter, {})) {
        removeFilter('age_num');
      }
    } else {
      setFilter('age_num', ageFilter, 'any');
    }
  };

  return (
    <>
      <fieldset className="sui-facet">
        <legend className="sui-facet__title">Age</legend>
        <div className="sui-facet-search grid grid-cols-2 gap-2">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder="Min"
            value={fromValue}
            onChange={e => {
              let v: number | undefined = parseInt(e.target.value);
              if (isNaN(v)) {
                v = undefined;
              }

              fromValueChange(v);
            }}
          />
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder="Max"
            value={toValue}
            onChange={e => {
              let v: number | undefined = parseInt(e.target.value);
              if (isNaN(v)) {
                v = undefined;
              }

              toValueChange(v);
            }}
          />

          <button
            className="btn btn-primary col-span-2 w-full"
            disabled={
              currentAgeFilter.from === fromValue &&
              currentAgeFilter.to === toValue
            }
            onClick={() => filterChanged({from: fromValue, to: toValue})}
          >
            Apply
          </button>
        </div>
      </fieldset>
    </>
  );
};

export default withSearch(
  ({filters, facets, addFilter, removeFilter, setFilter, a11yNotify}) => ({
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify,
  })
)(AgeRangeFilterContainer);
