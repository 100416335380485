/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useMemo, useState, useRef, useEffect} from 'react';
import {useQuery, useMutation} from 'react-query';

import _ from 'lodash';
import cx from 'classnames';

import {
  ErrorBoundary,
  SearchProvider,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
  withSearch,
  Facet,
} from '@elastic/react-search-ui';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import {HiOutlineX} from 'react-icons/hi';
import {
  RequestState,
  SearchRequest,
} from '@segmed/search-ui-elasticsearch-connector';
import {SearchContextState, SearchQuery} from '@elastic/search-ui';
import {Link} from 'react-router-dom';

import {useAuth} from '../../../hooks/auth';
import {
  buildSortOptionsFromConfig,
  getFacetFields,
} from '../../../config/config-helper';
import {ExtendedSearchDriverOptions} from '../../../models/minerva';
import {
  ReportSelectionEventType,
  isAnyReportSelected,
  isReportSelected,
  useReportSelection,
} from '../../../hooks/report-selection-provider';
import {trackEvent} from '../../../utils/tracking';
import {AddToDatasetDropdown} from '../../../components/dataset';
import {TagDropdown} from '../../../components/tags';
import {SaveSearchButton} from '../../../components/save-search';
import {getAvailableTags} from '../../../models/tags';
import {Loading} from '../../../core/components/loading';
import {formatNumber} from '../../../utils/strings';
import {ReportComponent} from '../../../components/report';
import {fetchReport} from '../../../models/report';
import {MenuDropdownSingle} from '../../../core/components/menu';
import {fetchMatchingStudyIds} from '../../../models/minerva';
import {SearchTableRows} from '../../../components/search-results/SearchTableRows';
import {EmbedVideo} from '../../../core/components/embed-video';
import {HiOutlineExternalLink} from 'react-icons/hi';
import {Card} from '../../../core/layout/card';
import {PatientSearch} from '../../../components/search-results/PatientSearch';
import {
  fetchDicomStudyInfo,
  getDicomSeries,
  getDicomAvailability,
} from '../../../models/dicom-viewer';
import {ToastMessage} from '../../../core/components/toast';
import {toast} from 'react-toastify';
import {SelectCustomNumberReportsModal} from '../../../components/search-results';
import {
  MinervaSearchForm,
  TextField,
  newTextField,
} from '../../../components/minerva-search-form';
import {extractSearchTerms, highlightSearchterm} from 'src/models/search';
import AgeRangeFilter from 'src/components/search-results/AgeRangeFilter';
import {PatientSortOption} from '../../../models/minerva';
import {processElasticSearchBody} from 'src/components/save-search/save-search-card';
import {buildElasticQuery} from '../../../components/minerva-search-form/MinervaSearchForm';
import {useAxios} from 'src/utils/http';

export const premadeDatasets: {
  label: string;
  bodyText: string;
  url: string;
}[] = [
  {
    label: '300 Chest X-rays with Pneumothorax + Control cases',
    bodyText: 'Positive cases: 150 | Negative (control) cases: 150',
    url: 'https://segmed.notion.site/Chest-X-rays-with-Pneumothorax-77a046ba51e24ee29ca1b6b615b4d654',
  },
  {
    label: '300 Head CT with Intracerebral Hemorrhage (ICH), no contrast',
    bodyText: 'Positive cases: 300',
    url: 'https://segmed.notion.site/Head-CT-with-Intracerebral-Hemorrhage-ICH-4ea63d995aed4583ac43bfe29a462a3f',
  },
  {
    label: '100 Prostate MR with varied PI-RADS',
    bodyText:
      'PI-RADS 1 cases : 25 | PI-RADS 2 cases: 25 | PI-RADS 3 cases: 50 | PI-RADS 4 cases: 50 | PI-RADS 5 cases: 50',
    url: 'https://segmed.notion.site/Prostate-MR-with-varied-PI-RADS-aa9629d801e14df1b6f215e2a0e338ff',
  },
];

export const formatFieldLabel = (field: string): string => {
  let res = field;
  if (res.endsWith('.keyword')) {
    res = res.substring(0, res.length - '.keyword'.length);
  }
  res = res.replace(/_/g, ' ');
  return res;
};

export const demoVideos: {
  label: string;
  url: string;
  embed: string;
}[] = [
  {
    label: 'How to search: a detailed guide',
    url: 'https://www.loom.com/share/6076d589bf4949369bbd9f6246137830',
    embed:
      'https://www.loom.com/embed/6076d589bf4949369bbd9f6246137830?hide_share=true&hide_owner=true&hideEmbedTopBar=true',
  },
  {
    label: 'How to order data: a full Openda walkthrough',
    url: 'https://www.loom.com/share/1a183ebc11c247eb88d78036211ad9d1',
    embed:
      'https://www.loom.com/embed/1a183ebc11c247eb88d78036211ad9d1?hide_share=true&hide_owner=true&hideEmbedTopBar=true',
  },
];

const ClearFilters = ({filters, clearFilters}: any) => {
  return (
    <button
      data-cy="clearFilterButton"
      onClick={() => clearFilters()}
      disabled={filters.length === 0}
      className="btn btn-secondary my-4"
    >
      Clear {filters.length} Filters
    </button>
  );
};

const ClearFilterButton = withSearch(({filters, clearFilters}) => ({
  filters,
  clearFilters,
}))(ClearFilters);

export const MinervaSearch = ({
  config,
  lastRequestBody,
  splitView = false,
  toggleGroupPatientIDs = () => {},
  onSubmit,
  patientCount,
  setSortPatientIDsBy = () => {},
  isInternalPage = false,
}: {
  config: ExtendedSearchDriverOptions;
  lastRequestBody?: SearchRequest;
  splitView?: boolean;
  toggleGroupPatientIDs?: () => void;
  onSubmit: (query?: Object) => void;
  patientCount?: number | null;
  setSortPatientIDsBy?: (option: PatientSortOption) => void;
  isInternalPage?: boolean;
}) => {
  const api = useAxios();

  const [openedStudyID, openedStudyIDChange] = useState<string>();
  const splitviewPanelLContainer = useRef<HTMLDivElement>(null);
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const [showAllStudiesPerPatient, showAllStudiesPerPatientChange] =
    useState<boolean>(false);
  const [additionalPatientStudies, additionalPatientStudiesChange] = useState<
    any[]
  >([]);
  const [studyIDs, studyIDsChange] = useState<string[]>([]);
  const [searchQueryWithFilter, searchQueryWithFilterChange] =
    useState<SearchQuery>();

  const {authState} = useAuth();

  const openedStudyIDChangeWithScroll = (studyId?: string) => {
    // save scroll position before changing study ID
    if (splitviewPanelLContainer.current) {
      localStorage.setItem(
        'searchPrevScrollPos',
        `${splitviewPanelLContainer.current.scrollTop}`
      );
    }

    return openedStudyIDChange(studyId);
  };
  const [customReportSelectionModalOpen, customReportSelectionModalOpenChange] =
    useState<boolean>(false);

  const studiesSelected = isAnyReportSelected(reportSelectionState);

  const {data: tags, isLoading: tagsLoading} = useQuery(
    ['tags'],
    () => getAvailableTags(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', openedStudyID],
    () => {
      return fetchReport(api, openedStudyID as string);
    },
    {
      enabled: !_.isEmpty(openedStudyID),
      keepPreviousData: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: openedDicomStudy} = useQuery(
    ['openedDicomStudy', openedStudyID],
    () => {
      return fetchDicomStudyInfo(api, openedStudyID!);
    },
    {
      enabled: !_.isNil(openedStudyID),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  useEffect(() => {
    // keep the scroll position of the left panel
    if (splitviewPanelLContainer.current) {
      const prevScrollPosStoredValue = localStorage.getItem(
        'searchPrevScrollPos'
      );
      const prevScrollPos = prevScrollPosStoredValue
        ? _.floor(_.toNumber(prevScrollPosStoredValue))
        : 0;
      splitviewPanelLContainer.current.scrollTop = prevScrollPos;
      localStorage.setItem(
        'searchPrevScrollPos',
        `${splitviewPanelLContainer.current.scrollTop}`
      );
    }
  }, [openedStudyID, openedDicomStudy, reportSelectionState]);

  const fetchStudyIdsMutation = useMutation(
    (params: {
      config: ExtendedSearchDriverOptions;
      requestState: RequestState;
      queryConfig: Object;
      numStudies?: number;
    }) => fetchMatchingStudyIds(params),
    {
      onMutate: async () => {
        // Unselect all reports before the mutation starts
        reportSelectionDispatch({
          type: ReportSelectionEventType.UNSELECT_ALL,
        });
      },
      onSuccess: studyIDs => {
        reportSelectionDispatch({
          type: ReportSelectionEventType.SELECT,
          payload: studyIDs,
        });
      },
    }
  );

  const {data: dicomAvailability = []} = useQuery(
    ['dicomAvailabilities', studyIDs],
    () => {
      return getDicomAvailability(api, studyIDs!);
    },
    {
      enabled: !_.isEmpty(studyIDs),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError: () => {
        const errMessage = 'Something went wrong fetching DICOM availability';
        toast(<ToastMessage title={errMessage} icon="error" />);
      },
    }
  );

  const intersperseAdditionalStudies = (
    results: any[],
    additionalPatientStudies: any[],
    sortPatientIDsBy: PatientSortOption
  ) => {
    const interspersedResults = [];
    const patientIds = _.uniq(
      results.map((result: any) => result['patient_id']?.['raw'])
    );

    for (const patientId of patientIds) {
      const patientResults = results.filter(
        (result: any) => result['patient_id']?.['raw'] === patientId
      );
      const patientAdditionalResults = additionalPatientStudies
        .map(study => {
          study['isAdditional'] = true;
          return study;
        })
        .filter((result: any) => result['patient_id']?.raw === patientId);
      interspersedResults.push(
        ..._.uniqBy(
          [...patientResults, ...patientAdditionalResults].sort(
            // Sort the results based on sortPatientIDsBy
            (reportA, reportB) => {
              switch (sortPatientIDsBy) {
                case 'Exam date ASC':
                  return (
                    new Date(reportA['exam_date']?.['raw']).getTime() -
                    new Date(reportB['exam_date']?.['raw']).getTime()
                  );
                case 'Exam date DESC':
                  return (
                    new Date(reportB['exam_date']?.['raw']).getTime() -
                    new Date(reportA['exam_date']?.['raw']).getTime()
                  );
                case 'Body Part':
                  return reportA['body_part']?.['raw'].localeCompare(
                    reportB['body_part']?.['raw']
                  );
                case 'Modality':
                  return reportA['modality']?.['raw'].localeCompare(
                    reportB['modality']?.['raw']
                  );
                default:
                  return 0;
              }
            }
          ),
          result => result.study_id?.raw
        )
      );
    }

    return interspersedResults;
  };

  useEffect(() => {
    // update default text fields with lastRequestBody on load if applicable
    if (lastRequestBody) {
      let newTextFields = [newTextField()];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [newUniqueQueries, filterList, operator] =
        processElasticSearchBody(lastRequestBody);
      if (operator === ' OR ') {
        const joinedQueries = Array.from(newUniqueQueries).join(' | ');
        newTextFields = [newTextField(joinedQueries)];
      } else {
        newTextFields = Array.from(newUniqueQueries).map(query =>
          newTextField(query)
        );
      }

      textFieldsChange(newTextFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [textFields, textFieldsChange] = useState<TextField[]>([
    newTextField(),
  ]);

  const CustomErrorView = ({error, children}: {error: any; children: any}) => {
    if (!error) {
      return children;
    }
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-center text-xl mb-4">
          Whoops! Searching through tens of millions of reports is no small
          feat! Our servers are catching their breath. Click refresh to jump
          back into your search for data.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="btn btn-primary"
        >
          Refresh
        </button>
      </div>
    );
  };
  const urlObject = new URL(window.location.href);
  // check if exists the autosubmit get param and if it's  "true"
  const autoSubmit = urlObject.searchParams.get('autosubmit') === 'true';

  return (
    <SearchProvider config={config}>
      <div>
        <div className="flex flex-col gap-y-4 p-4">
          <div className="">
            <MinervaSearchForm
              data-cy="SearchFormInput"
              onSubmit={query => {
                onSubmit(query);
              }}
              queryState={textFields}
              queryStateChange={query => {
                textFieldsChange(query);
              }}
              autoSubmit={autoSubmit}
            />
          </div>

          <WithSearch
            mapContextToProps={({
              wasSearched,
              results,
              rawResponse,
              isLoading,
              totalResults,
              pagingStart,
              pagingEnd,
              current,
              filters,
              resultsPerPage,
              searchTerm,
              sortDirection,
              sortField,
              sortList,
              sort,
              conditionalFacets,
              disjunctiveFacets,
              disjunctiveFacetsAnalyticsTags,
              search_fields,
            }) => ({
              wasSearched,
              results,
              rawResponse,
              isLoading,
              totalResults,
              pagingStart,
              pagingEnd,
              current,
              filters,
              resultsPerPage,
              searchTerm,
              sortDirection,
              sortField,
              sortList,
              sort,
              conditionalFacets,
              disjunctiveFacets,
              disjunctiveFacetsAnalyticsTags,
              search_fields,
            })}
          >
            {({
              wasSearched,
              results,
              rawResponse,
              isLoading,
              totalResults,
              pagingStart,
              pagingEnd,
              current,
              filters,
              resultsPerPage,
              searchTerm,
              sortDirection,
              sortField,
              sortList,
            }) => {
              const newStudyIDs = _.map(
                results,
                result => result['study_id']?.['raw']
              );
              const studyCount = _.find(
                _.find(rawResponse.facets, {
                  identifier: 'orig_lang.keyword',
                })?.entries,
                {label: 'en'}
              )?.count;

              // Update studyIDs only if they are different from the current state
              if (!_.isEqual(newStudyIDs, studyIDs)) {
                studyIDsChange(newStudyIDs);
              }

              const newSearchQueryWithFilter = {
                filters,
                searchQuery: config.searchQuery,
              };

              if (!_.isEqual(newSearchQueryWithFilter, searchQueryWithFilter)) {
                reportSelectionDispatch({
                  type: ReportSelectionEventType.UNSELECT_ALL,
                });
                searchQueryWithFilterChange(newSearchQueryWithFilter);
              }

              const searchTermSet = extractSearchTerms(lastRequestBody!);
              // get the first element of searchTermSet
              searchTerm =
                searchTermSet.size > 0
                  ? searchTermSet.values().next().value
                  : '';
              const requestState = {
                current,
                filters,
                resultsPerPage,
                searchTerm,
                sortDirection,
                sortField,
                sortList,
              };

              const queryConfig = {
                disjunctiveFacets: config.searchQuery.disjunctiveFacets,
                facets: config.searchQuery.facets,
                result_fields: config.searchQuery.result_fields,
                search_fields: config.searchQuery.search_fields,
                groupPatientIDs: config.searchQuery.groupPatientIDs,
              };

              const pageAllSelected =
                results.length > 0 &&
                _.every(results, result =>
                  isReportSelected(
                    reportSelectionState,
                    result['study_id']['raw']
                  )
                );

              const pageSomeSelected =
                pageAllSelected ||
                _.some(results, result =>
                  isReportSelected(
                    reportSelectionState,
                    result['study_id']['raw']
                  )
                );
              const selectPageOfReports = () => {
                trackEvent('SEARCH_SELECT_PAGE');
                reportSelectionDispatch({
                  type: ReportSelectionEventType.UNSELECT_ALL,
                });
                reportSelectionDispatch({
                  type: ReportSelectionEventType.SELECT,
                  payload: _.map(results, result => result['study_id']['raw']),
                });
              };
              const selectReports = (numStudies: number) => {
                if (numStudies > studyCount) {
                  toast(
                    <ToastMessage
                      title="Number of studies selected exceeds the total number of studies available"
                      icon="error"
                    />
                  );
                  return;
                }
                reportSelectionDispatch({
                  type: ReportSelectionEventType.UNSELECT_ALL,
                });
                const query = buildElasticQuery(textFields);

                fetchStudyIdsMutation.mutate({
                  config: config,
                  // marking searchTerm as empty because it will be constructed from the queryBuilderQuery
                  requestState: {...requestState, searchTerm: undefined},
                  queryConfig: {...queryConfig, queryBuilderQuery: query},
                  numStudies: numStudies,
                });
              };

              const SelectAllReports = () => {
                const totalToSelect = totalResults > 5000 ? 5000 : totalResults;
                trackEvent('SEARCH_SELECT_', {numStudies: totalToSelect});
                return (
                  <div onClick={() => selectReports(totalToSelect)}>
                    {totalResults > 5000
                      ? 'Select top 5000 matches'
                      : `Select all ${totalToSelect} results`}
                  </div>
                );
              };

              const SelectCustomNumberReportsModalWithSearchProps = withSearch(
                () => ({
                  selectReports: (numStudies: number) => {
                    trackEvent('SEARCH_SELECT_CUSTOM', {
                      numStudies: numStudies,
                    });
                    selectReports(numStudies);
                  },
                  closeModal: () => customReportSelectionModalOpenChange(false),
                })
              )(SelectCustomNumberReportsModal);

              const SelectAllReportsButton = withSearch(
                ({filters, facets}: SearchContextState) => ({
                  filters,
                  facets,
                })
              )(SelectAllReports);

              return (
                <ErrorBoundary
                  view={props => {
                    const {error, children} = props;
                    return (
                      <CustomErrorView error={error} children={children} />
                    );
                  }}
                >
                  {customReportSelectionModalOpen && (
                    <SelectCustomNumberReportsModalWithSearchProps />
                  )}
                  {isLoading && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                      <div className="inline-flex bg-black rounded p-4">
                        <Loading className="text-white" />
                      </div>
                    </div>
                  )}

                  {wasSearched && (
                    <>
                      <div className="flex justify-between">
                        <div className="flex">
                          <div data-cy="studiesShown">
                            {results.length > 0 &&
                              `Showing ${pagingStart} - ${pagingEnd} out of ${formatNumber(
                                showAllStudiesPerPatient
                                  ? studyCount + additionalPatientStudies.length
                                  : studyCount
                              )} studies available
                            `}
                            {results.length === 0 &&
                              'No search results found for your query'}
                          </div>
                          {config.searchQuery.groupPatientIDs &&
                            patientCount && (
                              <div className="ml-2">
                                {`with ${formatNumber(
                                  patientCount
                                )} Unique Patients`}
                              </div>
                            )}
                        </div>

                        <ResultsPerPage
                          options={[50, 100]}
                          data-cy="resultsPerPageSelector"
                        />
                      </div>
                      <div className={cx({splitview: splitView})}>
                        <div
                          ref={splitviewPanelLContainer}
                          className={cx({
                            'splitview-panel-l': splitView,
                          })}
                        >
                          <div className="flex gap-x-4 ">
                            <div className="flex flex-col">
                              <Sorting
                                label={'Sort by'}
                                sortOptions={buildSortOptionsFromConfig()}
                                className="w-72"
                                data-cy="sortByDropdown"
                              />
                              <ClearFilterButton data-cy="clearFilterButton" />

                              {getFacetFields().map((field: any) => {
                                const label = formatFieldLabel(field);
                                if (
                                  !isInternalPage &&
                                  [
                                    'body_part_llm.keyword',
                                    'contrast.keyword',
                                    'modality_llm.keyword',
                                    'study_description_llm.keyword',
                                  ].includes(field)
                                ) {
                                  return null;
                                }
                                return field === 'patient_id.keyword' ? (
                                  <PatientSearch
                                    key={field}
                                    config={config}
                                    results={results}
                                    showAllStudiesPerPatient={
                                      showAllStudiesPerPatient
                                    }
                                    showAllStudiesPerPatientChange={
                                      showAllStudiesPerPatientChange
                                    }
                                    additionalPatientStudiesChange={
                                      additionalPatientStudiesChange
                                    }
                                    toggleGroupPatientIDs={
                                      toggleGroupPatientIDs
                                    }
                                    setSortPatientIDsBy={setSortPatientIDsBy}
                                  />
                                ) : (
                                  <Facet
                                    key={field}
                                    field={field}
                                    label={label}
                                    filterType="any"
                                    isFilterable
                                  />
                                );
                              })}
                              <AgeRangeFilter />
                            </div>

                            <div className="overflow-hidden">
                              <div className="mb-4 flex items-center gap-x-2">
                                <div className="flex items-center">
                                  <input
                                    type="checkbox"
                                    data-tour="results-select-all"
                                    className="checkbox-input ml-6 mr-1"
                                    checked={pageAllSelected}
                                    disabled={results.length === 0}
                                    ref={el =>
                                      el &&
                                      (el.indeterminate = pageSomeSelected)
                                    }
                                    data-cy="Results_selectAllInput"
                                    onChange={e => {
                                      const checked = e.target.checked;

                                      trackEvent(
                                        checked
                                          ? 'SEARCH_SELECT_PAGE'
                                          : 'SEARCH_UNSELECT_PAGE'
                                      );

                                      reportSelectionDispatch({
                                        type: e.target.checked
                                          ? ReportSelectionEventType.SELECT
                                          : ReportSelectionEventType.UNSELECT,
                                        payload: _.map(
                                          results,
                                          result => result['study_id']['raw']
                                        ),
                                      });
                                    }}
                                  />
                                  <div>
                                    <MenuDropdownSingle
                                      buttonText=""
                                      useDefaultButtonClass={false}
                                      buttonClassName="px-2 selectDataDropdownButton"
                                      align="left"
                                      data-cy="selectDataDropdownButton"
                                      menuButtonDisabled={results.length === 0}
                                      menuItems={[
                                        {
                                          itemText: () => (
                                            <div data-cy="selectDataDropdown">
                                              Select all {results.length} on
                                              page
                                            </div>
                                          ),
                                          onClick: () => {
                                            selectPageOfReports();
                                          },
                                        },
                                        {
                                          itemText: () => (
                                            <SelectAllReportsButton />
                                          ),
                                        },
                                        {
                                          itemText: () => (
                                            <button
                                              onClick={e => {
                                                e.preventDefault();
                                                customReportSelectionModalOpenChange(
                                                  true
                                                );
                                              }}
                                            >
                                              Select Custom #
                                            </button>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                  {fetchStudyIdsMutation.isLoading && (
                                    <div className=" bg-black">
                                      <Loading className="block p-2 text-white" />
                                    </div>
                                  )}
                                  <span
                                    className="ml-2 font-normal"
                                    data-cy="numStudiesSelected"
                                  >{`${reportSelectionState.add.ids.size} Selected`}</span>
                                </div>

                                <AddToDatasetDropdown
                                  disabled={!studiesSelected}
                                  reportSelectionState={reportSelectionState}
                                />
                                <TagDropdown
                                  tags={userTags}
                                  studyIDs={Array.from(
                                    reportSelectionState.add.ids
                                  )}
                                  align="left"
                                  disabled={!studiesSelected || tagsLoading}
                                />
                                <SaveSearchButton
                                  userId={authState.profile?.userId ?? 0}
                                  studyCount={studyCount}
                                  lastRequestBody={lastRequestBody}
                                />
                              </div>
                              {/* ************************************************************ Start of the table here ************************************************************ */}
                              <div className="minerva-search-results border-b border-gray-200 sm:rounded-lg overflow-x-scroll m-2">
                                <table className="divide-y divide-gray-200">
                                  <thead className="bg-gray-50 divide-y divide-gray-200">
                                    <tr className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                      <th className="sticky z-10 bg-white left-0 w-[39px]"></th>
                                      <th className="text-sm px-6 py-4 sticky z-10 bg-white left-[41px] w-[190px]">
                                        Patient ID
                                      </th>
                                      <th className="text-sm px-6 py-4 sticky z-10 bg-white left-[176px] w-[94px]">
                                        Study ID
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Exam Date
                                      </th>
                                      {isInternalPage ? (
                                        <>
                                          <th className="text-sm px-6 py-4">
                                            Body Part LLM
                                          </th>
                                          <th className="text-sm px-6 py-4">
                                            Body Part from Data Partner
                                          </th>
                                          <th className="text-sm px-6 py-4">
                                            Modality LLM
                                          </th>
                                          <th className="text-sm px-6 py-4">
                                            Modality from Data Partner
                                          </th>
                                          <th className="text-sm px-6 py-4">
                                            Contrast
                                          </th>
                                        </>
                                      ) : (
                                        <>
                                          <th className="text-sm px-6 py-4">
                                            Body Part
                                          </th>
                                          <th className="text-sm px-6 py-4">
                                            Modality
                                          </th>
                                        </>
                                      )}
                                      <th className="text-sm px-6 py-4">
                                        View DICOMs
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Title
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Manufacturer
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Patient Age
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Patient Sex
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Model
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Source Location
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Site
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        State
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Report (Snippet)
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Dicom Headers (Study)
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Dicom Headers (Series)
                                      </th>
                                      <th className="text-sm px-6 py-4">
                                        Dicom Headers (Images)
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white ">
                                    {additionalPatientStudies.length &&
                                    showAllStudiesPerPatient ? (
                                      <SearchTableRows
                                        results={intersperseAdditionalStudies(
                                          results,
                                          additionalPatientStudies,
                                          config.searchQuery.sortPatientIDsBy
                                        )}
                                        openedStudyID={openedStudyID}
                                        openedStudyIDChange={
                                          openedStudyIDChangeWithScroll
                                        }
                                        groupPatientIDs={
                                          config.searchQuery.groupPatientIDs
                                        }
                                        dicomsAvailable={dicomAvailability}
                                        splitviewPanelLContainer={
                                          splitviewPanelLContainer
                                        }
                                      />
                                    ) : (
                                      <SearchTableRows
                                        results={results}
                                        openedStudyID={openedStudyID}
                                        openedStudyIDChange={
                                          openedStudyIDChangeWithScroll
                                        }
                                        groupPatientIDs={
                                          config.searchQuery.groupPatientIDs
                                        }
                                        dicomsAvailable={dicomAvailability}
                                        splitviewPanelLContainer={
                                          splitviewPanelLContainer
                                        }
                                        internalSearchPage={isInternalPage}
                                      />
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  ************************************************************ Start of split view here  ************************************************************  */}
                        {openedStudyID && (
                          <div
                            className={cx({
                              'splitview-panel-r xl:border-l-2 xl:border-b-0 border-b-2 border-gray-400':
                                splitView,
                            })}
                          >
                            <div>
                              <div className="sticky top-0 z-10 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-between space-x-2">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">
                                  <Link
                                    to={`/study/${openedStudyID}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    preventScrollReset={true}
                                    className="text-primary hover:text-primary-active"
                                  >
                                    {openedStudyID.slice(-7)}
                                  </Link>
                                </h3>
                                <button
                                  type="button"
                                  className={cx(
                                    'text-gray-400 hover:text-gray-500'
                                  )}
                                  onClick={() =>
                                    openedStudyIDChangeWithScroll(undefined)
                                  }
                                >
                                  <span className="sr-only">Close</span>

                                  <HiOutlineX className="h-6 w-6" />
                                </button>
                              </div>
                              <div className="p-6">
                                {report && (
                                  <ReportComponent
                                    report={{
                                      ...report,
                                      text:
                                        report.text &&
                                        lastRequestBody &&
                                        extractSearchTerms(lastRequestBody)
                                          ? highlightSearchterm(
                                              extractSearchTerms(
                                                lastRequestBody
                                              ),
                                              report.text
                                            )
                                          : report.text,
                                    }}
                                    PHIReported={() => {}}
                                    splitViewSearch={true}
                                    dicomSeries={getDicomSeries(
                                      openedStudyID,
                                      openedDicomStudy
                                    )}
                                  />
                                )}
                              </div>
                              {!report && (
                                <>
                                  {reportLoading && <Loading />}
                                  {reportError && (
                                    <div>Error loading report</div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/* Display if nothing has been searched. TODO: remove after IN-1302 */}
                  {!isLoading && wasSearched && (
                    <div className="flex justify-center bottom-0 w-full bg-white p-4 border-t">
                      <Paging />
                    </div>
                  )}
                  {!isLoading && !wasSearched && (
                    <div className="space-y-2 text-gray-900 p-6">
                      <div className="space-y-2 text-gray-900">
                        <div>
                          <div className="text-xl mb-2 font-medium">
                            Datasets ready to purchase today
                          </div>
                          <div className="text-md text-gray-900">
                            These pre-made datasets can be purchased as is.
                            Click on an option for details.
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
                          {premadeDatasets.map((dataset, i) => {
                            return (
                              <Card key={i}>
                                <div
                                  key={i}
                                  className="flex flex-col gap-y-1 text-sm"
                                >
                                  <a
                                    className="link font-sm"
                                    href={dataset.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                      trackEvent('CLICK_OPEN_PREMADE_DATASET', {
                                        label: dataset.label,
                                        url: dataset.url,
                                      })
                                    }
                                  >
                                    <div className="flex flex-row justify-between items-center">
                                      {dataset.label}
                                      <div className="mb-auto">
                                        <HiOutlineExternalLink className="w-5 h-5 inline-block ml-1" />
                                      </div>
                                    </div>
                                  </a>
                                  <div className="text-gray-500 text-xs">
                                    {dataset.bodyText}
                                  </div>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </div>

                      <div className="text-xl mb-2 font-medium">
                        Demo videos
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
                        {demoVideos.map((demo, i) => {
                          return (
                            <div key={i} className="flex flex-col">
                              <div className="text-md text-gray-900 pb-2">
                                {demo.label}
                              </div>
                              <div className="flex flex-col gap-y-1 text-smborder border-transparent rounded-md shadow-sm border-gray-300 z-10 overflow-hidden">
                                <EmbedVideo src={demo.embed} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </ErrorBoundary>
              );
            }}
          </WithSearch>
        </div>
      </div>
    </SearchProvider>
  );
};
