import _ from 'lodash';

import {BlackoutScopeEnum} from 'src/enums/blackoutscopeenum';
import {trackEvent} from '../utils/tracking';
import {BlackoutRectangleScopeType} from 'src/enums/BlackoutRectangleScopeEnum';
import {AxiosInstance} from 'axios';

interface EvalonResultResp {
  study_id: string;
  sop_id?: string;
  eval_name: string;
  fid: number;
}

interface DicomocrResultResp extends EvalonResultResp {
  phi_text: boolean;
  detected_text_phi?: string[];
  detected_text_other?: string[];
  detected_text: string[];
  phi_boxes: boolean;
  phi_max_confidence: number;
  blackout_status: string;
}

interface NerphiResultResp extends EvalonResultResp {
  end_char: number;
  label: string;
  start_char: number;
  text: string;
}

export interface NerphiResult extends EvalonResult {
  endChar: number;
  label: string;
  startChar: number;
  text: string;
}

interface EvalonErrorResultResp extends EvalonResultResp {
  message: string;
}

interface EvalonResult {
  studyID: string;
  sopID?: string;
  evalName: string;
  fid: number;
}

interface EvalonResultsResp {
  dicomocr?: DicomocrResultResp[];
  nerphi?: NerphiResultResp[];
  error?: EvalonErrorResultResp[];
}

interface EvalonResults {
  Dicomocr?: DicomocrResult[];
  Nerphi?: NerphiResult[];
  Error?: EvalonErrorResult[];
}

export interface DicomocrResult extends EvalonResult {
  phiText: boolean;
  detectedTextPHI: string;
  detectedTextOther: string;
  detectedText: string;
  phiBoxes: boolean;
  phiMaxConfidence: number;
  blackoutStatus: string;
}

export interface EvalonErrorResult extends EvalonResult {
  message: string;
}

const responseToEvalonResult = (evalonResp: EvalonResultResp): EvalonResult => {
  return {
    studyID: evalonResp.study_id,
    sopID: evalonResp.sop_id,
    evalName: evalonResp.eval_name,
    fid: evalonResp.fid,
  };
};

const responseToDicomocrResult = (
  dicomocrResp: DicomocrResultResp
): DicomocrResult => {
  return {
    ...responseToEvalonResult(dicomocrResp),
    phiText: dicomocrResp.phi_text,
    detectedTextPHI: dicomocrResp.detected_text_phi?.join(' | ') ?? '',
    detectedTextOther: dicomocrResp.detected_text_other?.join(' | ') ?? '',
    detectedText: dicomocrResp.detected_text?.join(' | ') ?? '',
    phiBoxes: dicomocrResp.phi_boxes,
    phiMaxConfidence: dicomocrResp.phi_max_confidence,
    blackoutStatus: dicomocrResp.blackout_status,
  };
};

const responseToNerphiResult = (nerphiResp: NerphiResultResp): NerphiResult => {
  return {
    ...responseToEvalonResult(nerphiResp),
    endChar: nerphiResp.end_char,
    label: nerphiResp.label,
    startChar: nerphiResp.start_char,
    text: nerphiResp.text,
  };
};

const responseToEvalonErrorResult = (
  evalonErrorResp: EvalonErrorResultResp
): EvalonErrorResult => {
  return {
    ...responseToEvalonResult(evalonErrorResp),
    message: evalonErrorResp.message,
  };
};

const evalonResultsRespToEvalonResults = (
  evalonResp: EvalonResultsResp
): EvalonResults => {
  return {
    Dicomocr: evalonResp.dicomocr?.map(dicomocrResp =>
      responseToDicomocrResult(dicomocrResp)
    ),
    Nerphi: evalonResp.nerphi?.map(nerphiResp =>
      responseToNerphiResult(nerphiResp)
    ),
    Error: evalonResp.error?.map(errorResp =>
      responseToEvalonErrorResult(errorResp)
    ),
  };
};

export const fetchDatasetEvalonReviewAdmin = (
  http: AxiosInstance,
  datasetID: number
) => {
  const scan_type = 'summary';
  return http
    .get(`/v1/admin/datasets/${datasetID}/review/eval_results/${scan_type}`)
    .then(response => {
      const {data} = response;

      const results = evalonResultsRespToEvalonResults(data);

      return results;
    });
};

export const fetchEvalonDicomocrByStudyID = (
  http: AxiosInstance,
  studyID: string
) => {
  return http
    .get(`/v1/admin/dicomocr/${studyID}`)
    .then((response: {data: FullEvalonResultsResp[]}) => {
      const {data} = response;
      return data;
    });
};

interface PHIEvalResponse {
  blackout_string?: string;
  boxes?: number[][];
  text?: string[];
}

interface DicomocrEvalResponse {
  blackout_string?: string;
  boxes?: number[][];
  text?: string[];
  phi?: PHIEvalResponse;
}

export interface FullEvalonResultsResp {
  fid: number;
  eval_name: string;
  eval_ver: number;
  cli_ver: number;
  sop_instance_uid: string;
  study_uid: string;
  location: string;
  eval_at: string;
  eval_response: DicomocrEvalResponse;
}

export const fetchDatasetAllEvalonReviewAdmin = (
  http: AxiosInstance,
  datasetID: number,
  studyID: string
) => {
  const scan_type = 'full';
  return http
    .get(`/v1/admin/datasets/${datasetID}/review/eval_results/${scan_type}`)
    .then((response: {data: FullEvalonResultsResp[]}) => {
      const {data} = response;
      // Filter the data array to only include items where the study_uid matches studyID
      const filteredData = data.filter(item => item.study_uid === studyID);
      return filteredData;
    });
};

export interface Rectangle {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface BlackoutRectangle extends Rectangle {
  scope: BlackoutRectangleScopeType;
  text?: string;
  is_phi?: boolean;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface ImageWithBlackoutModel {
  series_id: string;
  sop_id?: string;
  blackout: Rectangle[];
  scope: BlackoutScopeEnum;
  dimensions?: Dimensions;
}

export interface ImageWithBlackout {
  study_id: string;
  series_id: string;
  sop_id?: string;
  blackout: Rectangle[];
  scope: BlackoutRectangleScopeType;
  dimensions?: Dimensions;
}

export const imageWithBlackoutModelToImageWithBlackout = (
  imageWithBlackoutModel: ImageWithBlackoutModel[],
  studyId: string
): ImageWithBlackout[] => {
  return imageWithBlackoutModel.map(imageWithBlackout => ({
    study_id: studyId,
    series_id: imageWithBlackout.series_id,
    sop_id: imageWithBlackout.sop_id,
    blackout: imageWithBlackout.blackout,
    scope: imageWithBlackout.scope,
    dimensions: imageWithBlackout.dimensions,
  }));
};

export const imagesWithBlackoutToBlackoutRectangles = (
  imagesWithBlackout: ImageWithBlackout[]
): BlackoutRectangle[] => {
  const blackoutRectangles: BlackoutRectangle[] = _.chain(imagesWithBlackout)
    .flatMap(imagesWithBlackout => {
      return imagesWithBlackout.blackout.map(rectangle => ({
        ...rectangle,
        scope: imagesWithBlackout.scope,
      }));
    })
    .value();

  return blackoutRectangles;
};

export interface BlackoutCreateRequest {
  imageWithBlackout: ImageWithBlackoutModel[];
  studyID: string;
}

export interface BlackoutModel {
  blackoutId: number;
  studyId: string;
  blackoutSaved: ImageWithBlackoutModel[];
  reviewerUserId: number;
  applied: boolean;
  // TODO: support createdAt
  // createdAt: string;
}

export const responseToBlackout = (blackoutResp: {
  blackout_id: number;
  study_id: string;
  blackout_saved: string;
  reviewer_userid: number;
  applied: boolean;
}) => {
  const blackout: BlackoutModel = {
    blackoutId: blackoutResp.blackout_id,
    studyId: blackoutResp.study_id,
    blackoutSaved: savedBlackoutToImageWithBlackoutModel(
      blackoutResp.blackout_saved
    ),
    reviewerUserId: blackoutResp.reviewer_userid,
    applied: blackoutResp.applied,
  };

  return blackout;
};

export const createBlackout = (
  http: AxiosInstance,
  request: BlackoutCreateRequest
) => {
  trackEvent('BLACKOUT_CREATE', request);
  return http.post('/v1/admin/blackout', request).then(response => {
    const {data} = response;
    const blackout = responseToBlackout(data);
    return blackout;
  });
};

export const savedBlackoutToImageWithBlackoutModel = (
  savedBlackout: string
): ImageWithBlackoutModel[] => {
  const parsedBlackoutSaved = JSON.parse(savedBlackout);
  const blackouts = (
    (parsedBlackoutSaved?.blackout as ImageWithBlackoutModel[]) ?? []
  ).map(blackout => {
    if (blackout.dimensions?.width === 0) {
      delete blackout.dimensions;
    }
    return blackout;
  });
  return blackouts;
};

export const getBlackoutForStudyID = (http: AxiosInstance, studyID: string) => {
  trackEvent('BLACKOUT_GET', {studyID});
  return http
    .get(`/v1/admin/blackout/${studyID}`)
    .then(response => {
      const {data} = response;
      const blackout = responseToBlackout(data);
      return blackout.blackoutSaved;
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        return []; // Search is not saved
      } else {
        throw error;
      }
    });
};

export const cachedStatsBase = {
  area: 0,
  count: 0,
  max: 0,
  mean: 0,
  min: 0,
  stdDev: 0,
  variance: 0,
};

export interface CornerstoneEvent extends Event {
  detail: {
    startPoints: {
      world: [number, number, number];
    };
  };
}
