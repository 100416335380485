import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
  Reducer,
} from 'react';
import HTTPStatus from 'http-status';

import {initAmplitude} from '../utils/amplitude';
import {initLogrocket} from '../utils/logrocket';
import {trackEvent} from '../utils/tracking';
import {AuthProfile, AuthSession, fetchProfile} from '../models/auth';
import {useQuery, useQueryClient} from 'react-query';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';
import {useAuth0} from '@auth0/auth0-react';

export type AuthEvent =
  | {
      type:
        | AuthEventType.LOGIN_FAIL
        | AuthEventType.IS_NOT_LOGGED_IN
        | AuthEventType.RESET
        | AuthEventType.LOGOUT;
    }
  | {
      type: AuthEventType.LOGIN_SUCCEED | AuthEventType.IS_LOGGED_IN;
      payload: AuthProfile;
    };

// Context

const authSessionInitial: AuthSession = {
  loggedIn: false,
  loaded: false,
};

const AuthContext = createContext({
  authState: {
    loggedIn: false,
    loaded: false,
  } as AuthSession,
  authDispatch: null as unknown as React.Dispatch<AuthEvent>,
});

const useAuth = () => {
  return useContext(AuthContext);
};

// Reducer

export enum AuthEventType {
  LOGIN_SUCCEED = 'LOGIN_SUCCEED',
  LOGIN_FAIL = 'LOGIN_FAIL',
  IS_LOGGED_IN = 'IS_LOGGED_IN',
  IS_NOT_LOGGED_IN = 'IS_NOT_LOGGED_IN',
  RESET = 'RESET',
  LOGOUT = 'LOGOUT',
}

const authReducer: Reducer<AuthSession, AuthEvent> = (
  authState: AuthSession,
  action: AuthEvent
) => {
  switch (action.type) {
    case AuthEventType.LOGIN_SUCCEED: {
      const profile = action.payload;
      initAmplitude({userId: profile.email});
      initLogrocket({userId: profile.email});
      trackEvent('LOGGED_IN');
      return {
        ...authState,
        loaded: true,
        loggedIn: true,
        profile: profile,
      };
    }
    case AuthEventType.LOGIN_FAIL: {
      trackEvent('LOGIN_FAIL');
      return {...authState, loaded: true, loggedIn: false};
    }
    case AuthEventType.IS_LOGGED_IN: {
      const profile = action.payload;
      initAmplitude({userId: profile.email});
      initLogrocket({userId: profile.email});
      return {
        ...authState,
        loaded: true,
        loggedIn: true,
        profile: profile,
      };
    }
    case AuthEventType.IS_NOT_LOGGED_IN: {
      return {...authState, loaded: true, loggedIn: false};
    }
    case AuthEventType.LOGOUT: {
      trackEvent('LOGGED_OUT');
      initAmplitude({});
      // there is no concept of logging out of logrocket
      return {
        ...authState,
        loaded: false,
        loggedIn: false,
        profile: undefined,
      };
    }
    case AuthEventType.RESET: {
      return {...authState, ...authSessionInitial};
    }
    default:
      // Shouldn't reach here
      // @todo: Log error
      return authState;
  }
};

// Provider / Consumer

const AuthProvider = ({children}: {children: ReactNode}) => {
  const auth0 = useAuth0();
  const [authState, authDispatch] = useReducer(authReducer, authSessionInitial);
  const value = {authState, authDispatch};
  const queryClient = useQueryClient();
  const http = useAxios();
  const isLiveTest = process.env.REACT_APP_LIVE_TEST === 'true';

  useQuery(['profile', auth0.user?.sub], () => fetchProfile(http), {
    enabled: auth0.isAuthenticated,
    keepPreviousData: true,
    staleTime: 1 * 60 * 1000, // 1 minute
    onSuccess: profile => {
      // Log in successful
      authDispatch({type: AuthEventType.IS_LOGGED_IN, payload: profile});
    },
    onError: (err: AxiosError) => {
      if (err?.response) {
        const {response} = err;
        const responseCode = response && response.status;
        switch (responseCode) {
          case HTTPStatus.UNAUTHORIZED:
          case HTTPStatus.FORBIDDEN:
            authDispatch({type: AuthEventType.IS_NOT_LOGGED_IN});
            break;
          default:
            // Shouldn't reach here
            // @todo: Log error
            authDispatch({type: AuthEventType.IS_NOT_LOGGED_IN});
        }
      }
    },
    retryDelay: 1 * 1000, // Retry every second
  });

  useEffect(() => {
    if (isLiveTest) {
      // if live test, segmed server won't turn on authenticate flow, just return any profile the server sends back
      fetchProfile(http).then(response => {
        authDispatch({
          type: AuthEventType.IS_LOGGED_IN,
          payload: response,
        });
      });
    } else if (!authState.loaded) {
      queryClient.invalidateQueries(['profile']);
    }
  }, [authState.loaded, queryClient]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export {AuthProvider, AuthConsumer, useAuth};
