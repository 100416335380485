import {toast} from 'react-toastify';
import {AxiosInstance, AxiosResponse} from 'axios';

import {ToastMessage} from '../core/components/toast';

export interface updateDICOMPHIRequest {
  study_id: string;
  series_id: string;
}

export const updateDICOMPHI = (
  http: AxiosInstance,
  request: updateDICOMPHIRequest[]
) => {
  return http
    .put('/v1/dicoms/phi', request)
    .then((response: AxiosResponse<string[] | undefined>) => {
      const {data} = response;
      const sopIDs = data ?? [];

      toast(<ToastMessage title="DICOMs reported as PHI" icon="success" />);
      return sopIDs;
    });
};

export interface updateDICOMPHIReviewedRequest {
  study_id: string;
  series_id: string;
  phi_has: boolean;
}

export const updateDICOMPHIReviewed = (
  http: AxiosInstance,
  request: updateDICOMPHIReviewedRequest[]
) => {
  return http
    .put('/v1/admin/dicoms/review', request)
    .then((response: AxiosResponse<string[] | undefined>) => {
      const {data} = response;
      const sopIDs = data ?? [];

      toast(
        <ToastMessage
          title="DICOMs updated to clean and reviewed"
          icon="success"
        />
      );
      return sopIDs;
    });
};

interface DICOMImageResp {
  study_id: string;
  series_id: string;
  sop_id: string;
  phi_has: boolean;
  phi_needs_review: boolean;
  phi_reviewed_by: number;
  location: string;
}

export interface DICOMImage {
  studyID: string;
  sopID: string;
  seriesID: string;
  phiHas: boolean;
  phiNeedsReview: boolean;
  phiReviewedBy: number;
  location: string;
}

const responseToDICOMImage = (imageResp: DICOMImageResp): DICOMImage => {
  return {
    studyID: imageResp.study_id,
    seriesID: imageResp.series_id,
    sopID: imageResp.sop_id,
    phiHas: imageResp.phi_has,
    phiNeedsReview: imageResp.phi_needs_review,
    phiReviewedBy: imageResp.phi_reviewed_by,
    location: imageResp.location,
  };
};

export const fetchDICOMs = (http: AxiosInstance, sopIDs: string[]) => {
  return http
    .post('/v1/admin/dicoms/fetch', {sop_ids: sopIDs})
    .then((response: AxiosResponse<DICOMImageResp[]>) => {
      const {data} = response;
      return data.map(imageResp => responseToDICOMImage(imageResp));
    });
};
