import {AxiosInstance, AxiosResponse} from 'axios';

export interface AuthProfile {
  email: string;
  confirmed: boolean;
  firstname: string;
  lastname: string;
  orgId: number;
  userId: number;
  userType: 'customer' | 'data_partner';
  admin: boolean;
  tenant: string;
}

export interface AuthCredentials {
  S3Login: string;
  S3Pass: string;
  S3Directory: string;
  sourceIPs: string[];
}

export interface AuthSession {
  loggedIn: boolean;
  loaded: boolean;
  profile?: AuthProfile;
}

export const responseToProfile = (authProfileResp: {
  email: string;
  confirmed: boolean;
  firstname: string;
  lastname: string;
  org_id: number;
  user_id: number;
  user_type: 'customer' | 'data_partner';
  admin: boolean;
  tenant: string;
}): AuthProfile => {
  return {
    email: authProfileResp.email,
    confirmed: authProfileResp.confirmed,
    firstname: authProfileResp.firstname,
    lastname: authProfileResp.lastname,
    orgId: authProfileResp.org_id,
    userId: authProfileResp.user_id,
    userType: authProfileResp.user_type,
    admin: authProfileResp.admin,
    tenant: authProfileResp.tenant,
  };
};

export const logout = (http: AxiosInstance) => {
  return http.post('/v1/auth/logout').then(() => {
    return true;
  });
};

export const fetchProfile = (http: AxiosInstance) =>
  http.get('/v1/user/profile').then(response => {
    // Log in successful
    const {data} = response;
    return responseToProfile(data);
  });

export const fetchCredentials = (http: AxiosInstance) =>
  http.get('/v1/user/credentials').then(response => {
    const {data} = response;

    const credentials: AuthCredentials = {
      S3Login: data['s3_login'],
      S3Pass: data['s3_pass'],
      S3Directory: data['s3_directory'],
      sourceIPs: data['source_ips'],
    };

    return credentials;
  });

export const fetchIntercomHash = (http: AxiosInstance) =>
  http.get('/v1/user/intercom').then((response: AxiosResponse<string>) => {
    const {data} = response;
    return data;
  });

export const changeSourceIPs = (
  http: AxiosInstance,
  ips: string[] | 'current'
) => {
  const url =
    ips === 'current' ? '/v1/user/source_ips_current' : '/v1/user/source_ips';
  const body = ips === 'current' ? undefined : {source_ips: ips};
  return http.put(url, body);
};

interface RequestPasswordResetForm {
  email: string;
}

export const forgotPassword = (http: AxiosInstance, email: string) => {
  const form: RequestPasswordResetForm = {
    email: email,
  };

  return http.post('/v1/auth/password_reset_request', form);
};

export const login = (
  http: AxiosInstance,
  loginForm: {
    email: string;
    password: string;
    rememberMe: boolean;
  }
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loginForm = {...loginForm, email: loginForm.email.toLowerCase()};
  //return http.post('/v1/auth/login', loginForm);
  return;
};

export interface RegisterRequest {
  firstname: string;
  lastname: string;
  email: string;
  organization: string;
  purpose: string[];
  password: string;
  user_type: '' | 'customer' | 'data_partner';
}

export const register = (http: AxiosInstance, form: RegisterRequest) => {
  return http.post('/v1/auth/create_account', form);
};

export const verifyEmail = (http: AxiosInstance, form: {token: string}) => {
  return http.post('/v1/auth/verify_email', form);
};

export const verifyToken = (http: AxiosInstance, token: string) => {
  const form = {
    token,
  };
  return http.post('/v1/auth/verify_token', form);
};

export const resetPassword = (
  http: AxiosInstance,
  form: {
    token: string;
    new_password: string;
    confirm_password: string;
  }
) => {
  return http.post('/v1/auth/reset_password', form);
};

const invalidEmailDomains = [
  /* Default domains included */ 'aol.com',
  'att.net',
  'comcast.net',
  'facebook.com',
  'gmail.com',
  'gmx.com',
  'googlemail.com',
  'hotmail.com',
  'hotmail.co.uk',
  'mac.com',
  'me.com',
  'mail.com',
  'msn.com',
  'live.com',
  'sbcglobal.net',
  'verizon.net',
  'yahoo.com',
  'yahoo.co.uk',

  /* Other global domains */
  'email.com',
  'fastmail.fm',
  'games.com' /* AOL */,
  'gmx.net',
  'hush.com',
  'hushmail.com',
  'icloud.com',
  'iname.com',
  'inbox.com',
  'lavabit.com',
  'love.com' /* AOL */,
  'outlook.com',
  'pobox.com',
  'protonmail.ch',
  'protonmail.com',
  'proton.me',
  'pm.me',
  'tutanota.de',
  'tutanota.com',
  'tutamail.com',
  'tuta.io',
  'keemail.me',
  'rocketmail.com' /* Yahoo */,
  'safe-mail.net',
  'wow.com' /* AOL */,
  'ygm.com' /* AOL */,
  'ymail.com' /* Yahoo */,
  'zoho.com',
  'yandex.com',

  /* United States ISP domains */
  'bellsouth.net',
  'charter.net',
  'cox.net',
  'earthlink.net',
  'juno.com',

  /* British ISP domains */
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'live.co.uk',
  'ntlworld.com',
  'orange.net',
  'sky.com',
  'talktalk.co.uk',
  'tiscali.co.uk',
  'virgin.net',
  'bt.com',

  /* Domains used in Asia */
  'sina.com',
  'sina.cn',
  'qq.com',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'nate.com',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.com.sg',
  'yahoo.com.ph',
  '163.com',
  'yeah.net',
  '126.com',
  '21cn.com',
  'aliyun.com',
  'foxmail.com',

  /* French ISP domains */
  'hotmail.fr',
  'live.fr',
  'laposte.net',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'gmx.fr',
  'sfr.fr',
  'neuf.fr',
  'free.fr',

  /* German ISP domains */
  'gmx.de',
  'hotmail.de',
  'live.de',
  'online.de',
  't-online.de' /* T-Mobile */,
  'web.de',
  'yahoo.de',

  /* Italian ISP domains */
  'libero.it',
  'virgilio.it',
  'hotmail.it',
  'aol.it',
  'tiscali.it',
  'alice.it',
  'live.it',
  'yahoo.it',
  'email.it',
  'tin.it',
  'poste.it',
  'teletu.it',

  /* Russian ISP domains */
  'bk.ru',
  'inbox.ru',
  'list.ru',
  'mail.ru',
  'rambler.ru',
  'yandex.by',
  'yandex.com',
  'yandex.kz',
  'yandex.ru',
  'yandex.ua',
  'ya.ru',

  /* Belgian ISP domains */
  'hotmail.be',
  'live.be',
  'skynet.be',
  'voo.be',
  'tvcablenet.be',
  'telenet.be',

  /* Argentinian ISP domains */
  'hotmail.com.ar',
  'live.com.ar',
  'yahoo.com.ar',
  'fibertel.com.ar',
  'speedy.com.ar',
  'arnet.com.ar',

  /* Domains used in Mexico */
  'yahoo.com.mx',
  'live.com.mx',
  'hotmail.es',
  'hotmail.com.mx',
  'prodigy.net.mx',

  /* Domains used in Canada */
  'yahoo.ca',
  'hotmail.ca',
  'bell.net',
  'shaw.ca',
  'sympatico.ca',
  'rogers.com',

  /* Domains used in Brazil */
  'yahoo.com.br',
  'hotmail.com.br',
  'outlook.com.br',
  'uol.com.br',
  'bol.com.br',
  'terra.com.br',
  'ig.com.br',
  'r7.com',
  'zipmail.com.br',
  'globo.com',
  'globomail.com',
  'oi.com.br',
];

export const validateEmailDomain = (email: string): boolean => {
  const emailDomain = email.split('@').pop();

  if (!emailDomain || emailDomain.length === 0) {
    return false;
  }

  return invalidEmailDomains.includes(emailDomain);
};

const invalidEmailEndings = ['.ru', '.cu', '.ir', '.kp', '.sy', '.sd'];

export const validateEmailEnding = (email: string): boolean => {
  const emailDomain = email.split('@').pop();

  if (!emailDomain || emailDomain.length === 0) {
    return false;
  }

  return invalidEmailEndings.some(ending => emailDomain.endsWith(ending));
};

const orgRegex = RegExp(/^[._\- a-zA-Z0-9]*$/i);

export const validateOrganizationName = (name: string): boolean => {
  if (!name) {
    return false;
  }
  return !orgRegex.test(name);
};

export const isInternal = (email: string) => {
  return email.split('@').pop() === 'segmed.ai';
};

export const isTerminalCustomer = (email: string) => {
  return (
    email.split('@').pop() === 'external.segmed.ai' ||
    email.split('@').pop() === 'deepc.ai' ||
    email.split('@').pop() === 'corelinesoft.com'
  );
};

export const resendVerificationEmail = (
  http: AxiosInstance,
  userID: string
) => {
  return http.post('/v1/auth/resend_verification_email', {user_id: userID});
};
